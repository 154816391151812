import React from 'react';
import { Helmet } from 'react-helmet';
import TopMenu from '../../pages/private/MenuTop';
import EditShare from '../../pages/private/EditShare';
import Footer from '../../pages/public/Footer';

const Hooker_ManageSubscriptions = () => {
    return (
        <>
            <Helmet>
                <title>Index</title>

                {/* Microsoft Clarity */}
                <script type="text/javascript">
                    {
                        `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                        `
                    }
                </script>

                {/* Google Aalytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {
                        `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
        `
                    }
                </script>
            </Helmet>
            <section className='common-private-wrapper'>

                <div className='common-private-topMenu'>
                    <React.Fragment>
                        <TopMenu />
                    </React.Fragment>
                </div>
                <div className='common-private-bottomSide'>

                    <div className='common-private-content'>
                        <React.Fragment>
                            <EditShare />
                        </React.Fragment>
                    </div>
                </div>

                <div className='common-private-footer'>
                    <React.Fragment>
                        <Footer />
                    </React.Fragment>
                </div>

            </section>
        </>
    );
};

export default Hooker_ManageSubscriptions;
