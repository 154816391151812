import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/components/shares-modal.css';

function SharesModal({ contentType, initialData, onConfirm, onCancel }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const items = [
        { type: 'clothes', iconName: 'jacket', text: translations.clothesModal_jacket },
        { type: 'clothes', iconName: 'blazer', text: translations.clothesModal_blazer },
        { type: 'clothes', iconName: 'vest', text: translations.clothesModal_vest },
        { type: 'clothes', iconName: 'sweater', text: translations.clothesModal_sweatshirt },
        { type: 'clothes', iconName: 'blouse', text: translations.clothesModal_blouse },
        { type: 'clothes', iconName: 'dress', text: translations.clothesModal_dress },
        { type: 'clothes', iconName: 'polo', text: translations.clothesModal_polo },
        { type: 'clothes', iconName: 'shirt', text: translations.clothesModal_shirt },
        { type: 'clothes', iconName: 'tshirt', text: translations.clothesModal_tshirt },
        { type: 'clothes', iconName: 'jeans', text: translations.clothesModal_jeans },
        { type: 'clothes', iconName: 'pants', text: translations.clothesModal_pants },
        { type: 'clothes', iconName: 'shorts', text: translations.clothesModal_shorts },
        { type: 'clothes', iconName: 'skirt', text: translations.clothesModal_skirt },
        { type: 'clothes', iconName: 'pijama', text: translations.clothesModal_pijama },
        { type: 'clothes', iconName: 'swimwear', text: translations.clothesModal_swimwear },
        { type: 'clothes', iconName: 'bra', text: translations.clothesModal_bra },
        { type: 'clothes', iconName: 'boxers', text: translations.clothesModal_underwear },
        { type: 'clothes', iconName: 'panties', text: translations.clothesModal_panties },
        { type: 'clothes', iconName: 'socks', text: translations.clothesModal_socks },
        { type: 'clothes', iconName: 'other', text: translations.clothesModal_other },

        { type: 'accessories', iconName: 'cap', text: translations.accessoriesModal_cap },
        { type: 'accessories', iconName: 'hat', text: translations.accessoriesModal_hat },
        { type: 'accessories', iconName: 'glasses', text: translations.accessoriesModal_glasses },
        { type: 'accessories', iconName: 'gloves', text: translations.accessoriesModal_gloves },
        { type: 'accessories', iconName: 'scarf', text: translations.accessoriesModal_scarf },
        { type: 'accessories', iconName: 'watch', text: translations.accessoriesModal_watch },
        { type: 'accessories', iconName: 'belt', text: translations.accessoriesModal_belt },
        { type: 'accessories', iconName: 'handbag', text: translations.accessoriesModal_handbag },
        { type: 'accessories', iconName: 'necklace', text: translations.accessoriesModal_necklace },
        { type: 'accessories', iconName: 'ring', text: translations.accessoriesModal_ring },
        { type: 'accessories', iconName: 'earrings', text: translations.accessoriesModal_earrings },
        { type: 'accessories', iconName: 'bag', text: translations.accessoriesModal_bag },
        { type: 'accessories', iconName: 'bagpack', text: translations.accessoriesModal_bagpack },
        { type: 'accessories', iconName: 'tie', text: translations.accessoriesModal_tie },
        { type: 'accessories', iconName: 'bowtie', text: translations.accessoriesModal_bowtie },
        { type: 'accessories', iconName: 'bracelet', text: translations.accessoriesModal_bracelet },
        { type: 'accessories', iconName: 'wallet', text: translations.accessoriesModal_wallet },
        { type: 'accessories', iconName: 'other', text: translations.accessoriesModal_other },

        { type: 'footwear', iconName: 'shoes', text: translations.footwearModal_shoes },
        { type: 'footwear', iconName: 'sneakers', text: translations.footwearModal_sneakers },
        { type: 'footwear', iconName: 'sandals', text: translations.footwearModal_sandals },
        { type: 'footwear', iconName: 'mocassins', text: translations.footwearModal_mocassins },
        { type: 'footwear', iconName: 'boots', text: translations.footwearModal_boots },
        { type: 'footwear', iconName: 'ankleboots', text: translations.footwearModal_ankleboots },
        { type: 'footwear', iconName: 'highheel', text: translations.footwearModal_highheel },
        { type: 'footwear', iconName: 'elegantshoes', text: translations.footwearModal_elegantshoes },
        { type: 'footwear', iconName: 'flipflop', text: translations.footwearModal_flipflop },
        { type: 'footwear', iconName: 'slippers', text: translations.footwearModal_slippers },
        { type: 'footwear', iconName: 'other', text: translations.footwearModal_other }
    ];

    const initialDataObject = Array.isArray(initialData)
        ? initialData.reduce((acc, item) => {
            acc[item.iconName] = item.url;
            return acc;
        }, {})
        : initialData || {};

    const [referenceURLs, setReferenceURLs] = useState(initialDataObject);
    const [error, setError] = useState('');

    const [invalidURLs, setInvalidURLs] = useState({});

    // eslint-disable-next-line
    const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,7}/;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setReferenceURLs(initialDataObject);
    }, [initialData]);
    /* eslint-disable react-hooks/exhaustive-deps */

    const filteredItems = items.filter(item => item.type === contentType);

    const handleInputChange = (iconName, value) => {
        const trimmedValue = value.replace(/^(https?:\/\/)/, '');

        setReferenceURLs(prevState => ({
            ...prevState,
            [iconName]: trimmedValue
        }));

        if (urlRegex.test(trimmedValue) || trimmedValue === '') {
            setInvalidURLs(prevState => ({
                ...prevState,
                [iconName]: false
            }));
            setError('');
        } else {
            setInvalidURLs(prevState => ({
                ...prevState,
                [iconName]: true
            }));
            setError(translations.clothesModal_invalidURL);
        }
    };


    const handleConfirm = () => {
        const newInvalidURLs = {};

        Object.keys(referenceURLs).forEach(iconName => {
            if (!urlRegex.test(referenceURLs[iconName]) && referenceURLs[iconName] !== '') {
                newInvalidURLs[iconName] = true;
            }
        });

        if (Object.keys(newInvalidURLs).length > 0) {
            setInvalidURLs(newInvalidURLs);
            setError(translations.clothesModal_correctInvalidURLs);
        } else {
            setInvalidURLs({});
            setError('');
            onConfirm(referenceURLs);
        }
    };

    const handleCancel = () => {
        setReferenceURLs({});
        setError('');
        onCancel();
    };

    return (
        <section className='sharesModal-wrapper'>
            <div className='sharesModal-background'>
                <div className='sharesModal-topSide'>
                    <div className='sharesModal-title'>
                        <h5>{translations.clothesModal_mainTitleAddReferences}</h5>
                    </div>
                    <div className='sharesModal-close'>
                        <img src='/common/icon-close.png' alt='Close' onClick={handleCancel} />
                    </div>
                </div>

                <div className='sharesModal-middleSide'>
                    {filteredItems.map(item => (
                        <div key={item.iconName} className='sharesModal-reference-container'>
                            <div className='sharesModal-reference-icon'>
                                <img
                                    src={`/references/icon-${item.iconName}.png`}
                                    alt={item.text}
                                />
                            </div>
                            <div className='sharesModal-reference-content'>
                                <span>{item.text}</span>
                                <div className={
                                    (referenceURLs[item.iconName] === '' || referenceURLs[item.iconName] === undefined || referenceURLs[item.iconName] === null)
                                        ? 'sharesModal-profile-url-input-container'
                                        : invalidURLs[item.iconName]
                                            ? 'sharesModal-profile-url-input-container-error'
                                            : 'sharesModal-profile-url-input-container-active'
                                }>
                                    <span className={
                                        (referenceURLs[item.iconName] === '' || referenceURLs[item.iconName] === undefined || referenceURLs[item.iconName] === null)
                                            ? 'sharesModal-profile-url-prefix'
                                            : invalidURLs[item.iconName]
                                                ? 'sharesModal-profile-url-prefix-error'
                                                : 'sharesModal-profile-url-prefix-active'
                                    }>https://</span>

                                    <input
                                        type="text"
                                        className={
                                            (referenceURLs[item.iconName] === '' || referenceURLs[item.iconName] === undefined || referenceURLs[item.iconName] === null)
                                                ? 'sharesModal-profile-url-input'
                                                : invalidURLs[item.iconName]
                                                    ? 'sharesModal-profile-url-input-error'
                                                    : 'sharesModal-profile-url-input-active'
                                        }
                                        placeholder={`www.example.com/`}
                                        value={referenceURLs[item.iconName] || ''}
                                        onChange={(e) => handleInputChange(item.iconName, e.target.value)}
                                    />
                                </div>
                                {invalidURLs[item.iconName] && <div className="sharesModal-profile-url-error-message">{error}</div>}
                            </div>

                        </div>
                    ))}
                </div>

                <div className='sharesModal-bottomSide'>
                    <button onClick={handleConfirm}>{translations.clothesModal_confirm}</button>
                    <button onClick={handleCancel}>{translations.clothesModal_cancel}</button>
                </div>
            </div>
        </section>
    );

}

export default SharesModal;
