import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import MainContext from '../../../context/MainContext'
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/private/MyAccount/PersonalInformation.css';

function MyAccount() {
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const genderOptions = [
        { value: 'male', label: translations.private_account_personalInfo_genderMan },
        { value: 'female', label: translations.private_account_personalInfo_genderWoman },
    ];

    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadCountryList();
        fetchUserInformation();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function loadCountryList() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/countries`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                setCountryOptions(data.countries);
            })
            .catch(error => {
                console.error('Error fetching public data:', error);
                setLoading(false);
            });
    }

    async function fetchUserInformation() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/personal-information`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403) {
                        logout(language);
                    }
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setName(data.name);
                setSurname(data.surname);
                setEmail(data.email);
                setGender(data.gender);
                setBirthday(data.birthday);
                setCountry(data.country);
                setLoadingInfo(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const updateUserInformation = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/personal-information`;

        const formData = new URLSearchParams({
            name: name,
            surname: surname,
            gender: gender,
            birthday: birthday,
            country: country
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setError(translations.private_account_personalInfo_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const fullName = name + " " + surname
                localStorage.setItem('name', fullName);
                setLoading(false);
                setSuccess(translations.private_account_personalInfo_informationUpdated);
            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    return (
        <section className='myAccount-personalInformation-content'>

            <div className='myAccount-border-descriptor'>
                <h3>{translations.private_account_personalInfo_mainTitle}</h3>
                <p>{translations.private_account_personalInfo_subTitle}</p>
            </div>

            <div className='myAccount-border-content'>

                {loadingInfo ? (
                    <div className='myAccount-personalInformation-loading'>
                        <img src='/loaders/icon-loading.png' alt='Loading' />
                    </div>
                ) : (
                    <form onSubmit={updateUserInformation}>
                        <div className='myAccount-personalInformation-name-divider'>
                            <div className='myAccount-personalInformation-name'>
                                <label>{translations.private_account_personalInfo_firstName}</label>
                                <input
                                    type="text"
                                    className="myAccount-personalInformation-input"
                                    placeholder={translations.private_account_personalInfo_firstName_placeholder}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='myAccount-personalInformation-surname'>
                                <label>{translations.private_account_personalInfo_lastName}</label>
                                <input
                                    type="text"
                                    className="myAccount-personalInformation-input"
                                    placeholder={translations.private_account_personalInfo_lastName_placeholder}
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className='myAccount-personalInformation-email'>
                            <label>{translations.private_account_personalInfo_email}</label>
                            <input
                                type="text"
                                className="myAccount-personalInformation-input"
                                value={email}
                                disabled
                            />
                            <span>{translations.private_account_personalInfo_email_info}</span>
                        </div>

                        <div className='myAccount-personalInformation-name-divider'>
                            <div className='myAccount-personalInformation-gender'>
                                <label htmlFor="gender">{translations.private_account_personalInfo_gender}</label>
                                <div className='myAccount-personalInformation-select-container'>
                                    <select
                                        id="gender"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        className='myAccount-personalInformation-select'
                                        required >
                                        <option value="" disabled>{translations.private_account_personalInfo_selectGender}</option>
                                        {genderOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='myAccount-personalInformation-birthday'>
                                <label>{translations.private_account_personalInfo_birthday}</label>
                                <input
                                    type='date'
                                    value={birthday}
                                    onChange={(e) => setBirthday(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className='myAccount-personalInformation-country'>
                            <label htmlFor="country">{translations.private_account_personalInfo_country}</label>
                            <div className='myAccount-personalInformation-select-container'>
                                <select
                                    id="country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    className='myAccount-personalInformation-select'
                                    required
                                >
                                    <option value="" disabled>{translations.private_account_personalInfo_country_selectYours}</option>
                                    {countryOptions.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {error !== '' && (
                            <div className='myAccount-personalInformation-error'>
                                <span>{error}</span>
                            </div>
                        )}

                        {success !== '' && (
                            <div className='myAccount-personalInformation-success'>
                                <span>{success}</span>
                            </div>
                        )}

                        <button type='submit' className='myAccount-personalInformation-button-submit'>
                            {loading ? (
                                <img src='/loaders/icon-loading.png' alt='' />
                            ) : (
                                <>
                                    {translations.private_account_personalInfo_updateProfile}
                                </>
                            )}
                        </button>

                    </form>
                )}

            </div>
        </section >
    );
}

export default MyAccount;
