import React from 'react';
import SECTION0_HEADER from './LandingPage/Section0_Header'
import SECTION1_HERO from './LandingPage/Section1_Hero'
import SECTION2_DASHBOARD from './LandingPage/Section2_Dashboard'
import SECTION3_SHARE from './LandingPage/Section3_Share'
import SECTION4_ADVANTAGESGENERAL from './LandingPage/Section4_AdvantagesGeneral'
import SECTION5_PARTNERWITHUS from './LandingPage/Section5_PartnerWithUs'
import SECTION6_PREMIUMFEATURES from './LandingPage/Section6_PremiumFeatures'
import SECTION7_FAQ from './LandingPage/Section7_FAQ'
import SECTION8_CONTACTS from './LandingPage/Section8_Contacts'
import '../../style/public/LandingPage.css';

function Index() {
    return (
        <section className='landingPage-content'>
            <SECTION0_HEADER />
            <SECTION1_HERO />
            <SECTION2_DASHBOARD />
            <SECTION3_SHARE />
            <SECTION4_ADVANTAGESGENERAL />
            <SECTION5_PARTNERWITHUS />
            <SECTION6_PREMIUMFEATURES />
            <SECTION7_FAQ />
            <SECTION8_CONTACTS />
        </section>
    );
}

export default Index;
