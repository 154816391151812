import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaTags, FaCopyright } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import MainContext from '../context/MainContext';
import ShareButtons from './shareButtons';
import ConfirmationModal from './confirmation-modal';
import ReportProblem from './reportProblem'
import '../style/components/share.css';

function ShareComponent({ shareData, language, translations, likedShare, setLikedShare, savedShare, setSavedShare }) {
    const navigate = useNavigate();
    const [previousUrl, setPreviousUrl] = useState('');

    const [reportProblemOpen, setReportProblemOpen] = useState(false);

    const { getSessionInfo, isAuthenticated } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo()

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const share = shareData.share.share;
    const extension = shareData.share.extension;
    const shareUsername = shareData.share.username;
    const shareGender = shareData.share.gender;
    const shareStyle = shareData.share.style;
    const shareCopyrights = shareData.share.copyrights === ''
        ? ''
        : shareData.share.copyrights.startsWith('http://') || shareData.share.copyrights.startsWith('https://')
            ? shareData.share.copyrights
            : `https://${shareData.share.copyrights}`;
    const shareViews = shareData.share.views;

    const clothing = shareData.clothing;
    const accessories = shareData.accessories
    const footwear = shareData.footwear;

    useEffect(() => {
        const referrer = document.referrer;
        const urlFromShares = referrer.startsWith(window.location.origin + `/${language}/shares/`);
        const urlFromProfile = referrer.startsWith(window.location.origin + `/${language}/@`);

        if (urlFromShares) {
            setPreviousUrl(referrer);
        } else if (urlFromProfile) {
            setPreviousUrl(`/${language}/@${shareUsername.toLowerCase()}`);
        } else {
            setPreviousUrl(`/${language}/mural`);
        }
    }, [language, shareGender, shareStyle, shareUsername]);

    const handleEdit = () => {
        navigate(`/${language}/dashboard/${shareUsername}/edit-share/${share}`, { state: { shareData } });
    };

    useEffect(() => {
        if (confirmationModalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [confirmationModalOpen]);

    const handleConfirm = () => {
        setConfirmationModalOpen(false);
        deleteShare();
    };

    const handleCancel = () => {
        setConfirmationModalOpen(false);
    };

    async function deleteShare() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/delete/share`;

        const data = new URLSearchParams({
            picture: share,
        });

        fetch(`${baseURL}?${data}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                navigate(`/${language}/@${localStoredUsername}`);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const likeShare = (action) => {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/like-share`;

        const formData = new URLSearchParams({
            share: share,
            action: action,
            liked: likedShare
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLikedShare(data.result);
            })
            .catch(error => {
                setLikedShare(undefined);
            });
    };

    const saveShare = (action) => {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/save-share`;

        const formData = new URLSearchParams({
            share: share,
            action: action,
            saved: savedShare
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setSavedShare(data.result);
            })
            .catch(error => {
                setSavedShare(undefined);
            });
    };

    return (
        <section className='share-content'>
            <div className='share-image'>
                <img src={`${MainContext.storageURL}${share}${extension}`} alt={`User ${shareUsername} shared ${share} on the ${shareGender} collection of ${shareStyle} style`} />
            </div>
            <div className='share-information-wrapper'>
                <div className='share-information'>
                    <div className='share-options'>
                        <div className='share-return'>
                            <a href={previousUrl}>
                                <img src='/common/icon-return-arrow.png' alt='Return back' />
                            </a>
                        </div>

                        {localStoredUsername === shareUsername && isAuthenticated && (
                            <>
                                <button className="share-edit-button" onClick={handleEdit}>
                                    <svg className="share-edit-svgIcon" viewBox="0 0 512 512">
                                        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                    </svg>
                                </button>


                                <button className="share-button-delete" onClick={() => setConfirmationModalOpen(true)}>
                                    <svg viewBox="0 0 448 512" className="share-svgIcon-delete"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                </button>
                            </>
                        )}
                    </div>

                    <div className='share-information'>
                        <div className='share-details'>
                            <h2>{translations.share_mainTitle}</h2>
                            <div className='details-grid'>
                                <div className='detail-item'>
                                    <FaUser className='detail-icon' />
                                    <label>{translations.share_sharedBy}</label>
                                    <a href={`/${language}/@${shareUsername}`}>@{shareUsername}</a>
                                </div>
                                <div className='detail-item'>
                                    <FaTags className='detail-icon' />
                                    <label>{translations.share_style}</label>
                                    <span>{shareStyle}</span>
                                </div>
                                <div className='detail-item'>
                                    <FaCopyright className='detail-icon' />
                                    <label>{translations.share_copyrights}</label>
                                    {shareCopyrights === '' ? (
                                        <a href={`/${language}/@${shareUsername}`}>@{shareUsername}</a>
                                    ) : (
                                        <a href={shareCopyrights} target="_blank" rel="noopener noreferrer">
                                            {translations.share_copyrights_custom}
                                        </a>
                                    )}
                                </div>
                            </div>
                            {isAuthenticated && (
                                <div className='likeAndSave-wrapper'>
                                    {likedShare !== undefined && (
                                        <>
                                            {likedShare ? (
                                                <img src='/common/like-after.png' alt='After Like Share' onClick={() => likeShare(false)} />
                                            ) : (
                                                <img src='/common/like-before.png' alt='Before Like Share' onClick={() => likeShare(true)} />
                                            )}
                                        </>
                                    )}

                                    {savedShare !== undefined && isAuthenticated && (
                                        <>
                                            {savedShare ? (
                                                <img src='/common/save-after.png' alt='After Save Share' onClick={() => saveShare(false)} />
                                            ) : (
                                                <img src='/common/save-before.png' alt='Before Save Share' onClick={() => saveShare(true)} />
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <CategorySection
                            share={share}
                            category='clothes'
                            items={clothing.items}
                            username={localStoredUsername}
                            language={language}
                            translations={translations}
                        />
                        <CategorySection
                            share={share}
                            category='accessories'
                            items={accessories.items}
                            username={localStoredUsername}
                            language={language}
                            translations={translations}
                        />
                        <CategorySection
                            share={share}
                            category='footwear'
                            items={footwear.items}
                            username={localStoredUsername}
                            language={language}
                            translations={translations}
                        />
                    </div>

                    {confirmationModalOpen && (
                        <ConfirmationModal
                            onConfirm={handleConfirm}
                            onCancel={handleCancel}
                            language={language}
                            translations={translations}
                        />
                    )}

                </div>

                <div className='share-views'>
                    <div className='share-views-leftSide'>
                        <p>{shareViews} {translations.share_peopleViewShares}</p>
                    </div>
                    <div className='share-views-rightSide'>
                        <p onClick={() => setReportProblemOpen(true)}>{translations.share_reportProblem}</p>
                    </div>
                </div>

                <div>
                    <ShareButtons
                        url={`https://www.matchthelook.com/${language}/${shareUsername}/share/${share}`}
                        title={`Check out this share of @${shareUsername} on Match the Look!`}
                    />
                </div>
            </div>

            {reportProblemOpen && (
                <ReportProblem
                    username={shareUsername}
                    share={share}
                    setReportProblemOpen={setReportProblemOpen}
                />
            )}

        </section>
    );
}

export default ShareComponent;


const CategorySection = ({ share, category, count, items, username, language, translations }) => {
    const [loadTime, setLoadTime] = useState(0);

    const filteredItems = Object.keys(items).filter(itemKey => items[itemKey]);

    // Track when the share is loaded and store the time
    useEffect(() => {
        const timeLoaded = performance.now();
        setLoadTime(timeLoaded);
    }, []);

    if (count === 0 || filteredItems.length === 0) return null;

    const getTranslationKey = (category, itemKey) => {
        return `${category.toLowerCase()}Modal_${itemKey}`;
    };

    const getTranslatedText = (category, itemKey) => {
        const translationKey = getTranslationKey(category, itemKey);
        const translatedText = translations[translationKey];
        return translatedText ? translatedText.charAt(0).toUpperCase() + translatedText.slice(1) : itemKey.charAt(0).toUpperCase() + itemKey.slice(1);
    };

    const handleLinkClick = (itemKey, absoluteUrl) => {
        const currentTime = performance.now();
        const timeElapsed = currentTime - loadTime;
        console.log(`Share: ${share}, Category: ${category}, Item: ${itemKey}, Time to click: ${timeElapsed.toFixed(2)} ms`);

        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/save/referenceclick`;

        const formData = new URLSearchParams({
            username: username,
            share: share,
            category: category,
            itemKey: itemKey,
            timeElapsed: timeElapsed,
            url: absoluteUrl
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {

            });
    };

    return (
        <div className='share-categories'>
            {language === 'pt' ? (
                <>
                    {(category.charAt(0).toUpperCase() + category.slice(1)) === 'Clothes' && (<h2>{translations.share_referencesTitleClothes}</h2>)}
                    {(category.charAt(0).toUpperCase() + category.slice(1)) === 'Accessories' && (<h2>{translations.share_referencesTitleAccessories}</h2>)}
                    {(category.charAt(0).toUpperCase() + category.slice(1)) === 'Footwear' && (<h2>{translations.share_referencesTitleFootwear}</h2>)}
                </>
            ) : (
                <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
            )}
            <div className='share-categories-buttons'>
                {filteredItems.map(itemKey => {
                    const url = items[itemKey];

                    // If the URL does not start with 'http://' or 'https://', prepend 'https://'
                    const absoluteUrl = url.startsWith('http://') || url.startsWith('https://')
                        ? url
                        : `https://${url}`;

                    return (
                        <a key={itemKey} href={absoluteUrl} target='_blank' rel='noopener noreferrer' onClick={() => handleLinkClick(itemKey, absoluteUrl)}>
                            <img src={`/references/icon-${itemKey}.png`} alt={`${getTranslatedText(category, itemKey)} Icon`} />
                            {getTranslatedText(category, itemKey)}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};
