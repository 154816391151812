import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation();

  const getLanguageFromPath = (pathname) => {
    return pathname.startsWith('/pt') ? 'pt' : 'en';
  };

  const [language, setLanguage] = useState(getLanguageFromPath(location.pathname));

  const toggleLanguage = (v_language) => {
    const currentPath = location.pathname.replace(/^\/(en|pt)/, '');
    const newPath = v_language === 'pt' ? `/pt${currentPath}` : `/en${currentPath}`;
    window.location.href = newPath;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentLanguage = getLanguageFromPath(location.pathname);
    if (currentLanguage !== language) {
      setLanguage(currentLanguage);
    }
  }, [location.pathname]);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};