import React, { useState } from 'react';
import MainContext from '../context/MainContext';
import '../style/components/trendy-looks.css';

function ImageWithLoading({ src, alt, translations }) {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className='trendyLooks-item-content'>
            {!isLoaded && (
                <div className='trendyLooks-placeholder-loading'>
                    <img src='/loaders/icon-loading.png' alt='Loading' />
                </div>
            )}
            <img
                src={src}
                alt={alt}
                onLoad={() => setIsLoaded(true)}
                className={isLoaded ? 'trendyLooks-image' : 'trendyLooks-image-loading'}
            />
            {isLoaded && (
                <div className='trendyLooks-info'>
                    <div className='trendyLooks-info-content'>
                    <span>{translations.allShares_openShare}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

function TrendyLooks({ language, translations, trendyLooks }) {
    return (
        <section className={trendyLooks.length < 1 ? ('') : ('trendyLooks-wrapper')}>
            {trendyLooks.length > 0 && (
                <>
                    <div className='trendyLooks-wrapper-title'>{translations.allShares_trendyLooks_title}</div>
                    <div className='trendyLooks-container'>
                        <div className='trendyLooks-gallery'>
                            {trendyLooks.map((item, index) => (
                                <a href={`/${language}/${item.author}/share/${item.image}`} key={index}>
                                    <div className='trendyLooks-item' key={index}>
                                        <ImageWithLoading
                                            src={MainContext.storageURL + item.image + item.extension}
                                            alt={`Share ${item.image} shared by ${item.author}`}
                                            translations={translations}
                                        />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            )}

        </section>
    );
}

export default TrendyLooks;
