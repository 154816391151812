import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import LoadingComponent from '../../components/share-loading';
import ShareComponent from '../../components/share';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/Share.css';

function Share() {
    const { language } = useContext(LanguageContext);
    const { username, share } = useParams();
    const { isAuthenticated, getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [likedShare, setLikedShare] = useState(false);
    const [savedShare, setSavedShare] = useState(false);

    const [loading, setLoading] = useState(true);

    const [shareData, setShareData] = useState({
        share: {
            share: '',
            extension: '',
            username: '',
            gender: '',
            style: '',
            copyrights: '',
            views: ''
        },
        clothing: {
            count: 0,
            items: {
                jacket: '',
                blazer: '',
                vest: '',
                sweater: '',
                blouse: '',
                dress: '',
                polo: '',
                shirt: '',
                tshirt: '',
                pants: '',
                jeans: '',
                shorts: '',
                skirt: '',
                pijama: '',
                swimwear: '',
                boxers: '',
                bra: '',
                panties: '',
                socks: '',
                other: ''
            }
        },
        accessories: {
            count: 0,
            items: {
                cap: '',
                hat: '',
                gloves: '',
                scarf: '',
                watch: '',
                belt: '',
                handbag: '',
                necklace: '',
                ring: '',
                earrings: '',
                bag: '',
                bagpack: '',
                tie: '',
                bowtie: '',
                bracelet: '',
                wallet: '',
                glasses: '',
                other: ''
            }
        },
        footwear: {
            count: 0,
            items: {
                shoes: '',
                sneakers: '',
                sandals: '',
                mocassins: '',
                boots: '',
                ankleboots: '',
                highheels: '',
                elegantshoes: '',
                flipflops: '',
                slippers: '',
                other: ''
            }
        },
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoading(true);
        loadShareInfo();
        if(isAuthenticated) {
            shareLiked();
            shareSaved();
        }
        incrementShareView();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function loadShareInfo() {
        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/fetch/share`;

        const data = new URLSearchParams({
            username: username.toLocaleLowerCase(),
            share: share
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setShareData({
                    share: {
                        share: data.share.share || '',
                        extension: data.share.extension || '',
                        username: data.share.username || '',
                        gender: data.share.gender || '',
                        style: data.share.style || '',
                        copyrights: data.share.copyrights || '',
                        views: data.share.viewCounting || '',
                    },
                    clothing: {
                        count: data.clothing.count || 0,
                        items: {
                            jacket: data.clothing.items.jacket || '',
                            blazer: data.clothing.items.blazer || '',
                            vest: data.clothing.items.vest || '',
                            sweater: data.clothing.items.sweater || '',
                            blouse: data.clothing.items.blouse || '',
                            dress: data.clothing.items.dress || '',
                            polo: data.clothing.items.polo || '',
                            shirt: data.clothing.items.shirt || '',
                            tshirt: data.clothing.items.tshirt || '',
                            pants: data.clothing.items.pants || '',
                            jeans: data.clothing.items.jeans || '',
                            shorts: data.clothing.items.shorts || '',
                            skirt: data.clothing.items.skirt || '',
                            pijama: data.clothing.items.pijama || '',
                            swimwear: data.clothing.items.swimwear || '',
                            boxers: data.clothing.items.boxers || '',
                            bra: data.clothing.items.bra || '',
                            panties: data.clothing.items.panties || '',
                            socks: data.clothing.items.socks || '',
                            other: data.clothing.items.other || ''
                        }
                    },
                    accessories: {
                        count: data.accessories.count || 0,
                        items: {
                            cap: data.accessories.items.cap || '',
                            hat: data.accessories.items.hat || '',
                            gloves: data.accessories.items.gloves || '',
                            scarf: data.accessories.items.scarf || '',
                            watch: data.accessories.items.watch || '',
                            belt: data.accessories.items.belt || '',
                            handbag: data.accessories.items.handbag || '',
                            necklace: data.accessories.items.necklace || '',
                            ring: data.accessories.items.ring || '',
                            earrings: data.accessories.items.earrings || '',
                            bag: data.accessories.items.bag || '',
                            bagpack: data.accessories.items.bagpack || '',
                            tie: data.accessories.items.tie || '',
                            bowtie: data.accessories.items.bowtie || '',
                            bracelet: data.accessories.items.bracelet || '',
                            wallet: data.accessories.items.wallet || '',
                            glasses: data.accessories.items.glasses || '',
                            other: data.accessories.items.other || ''
                        }
                    },
                    footwear: {
                        count: data.footwear.count || 0,
                        items: {
                            shoes: data.footwear.items.shoes || '',
                            sneakers: data.footwear.items.sneakers || '',
                            sandals: data.footwear.items.sandals || '',
                            mocassins: data.footwear.items.mocassins || '',
                            boots: data.footwear.items.boots || '',
                            ankleboots: data.footwear.items.ankleboots || '',
                            highheel: data.footwear.items.highheel || '',
                            elegantshoes: data.footwear.items.elegantshoes || '',
                            flipflop: data.footwear.items.flipflop || '',
                            slippers: data.footwear.items.slippers || '',
                            other: data.footwear.items.other || ''
                        }
                    },
                    userOwnShare: data.userOwnShare
                });

                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    async function incrementShareView() {

        const data = new URLSearchParams({
            username: localStoredUsername,
            share: share
        });

        fetch(`${MainContext.apiURL}/api/v1/public/shares/increment/share-view?${data}`, {
            method: 'GET',
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                console.log(error);
            });
    }

    async function shareLiked() {
        const data = new URLSearchParams({
            username: localStoredUsername,
            share: share
        });

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/share-liked?${data}`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLikedShare(data.result);
            })
            .catch(error => {
                setLikedShare(undefined);
            });
    }

    async function shareSaved() {
        const data = new URLSearchParams({
            username: localStoredUsername,
            share: share
        });

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/share-saved?${data}`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setSavedShare(data.result);
            })
            .catch(error => {
                setSavedShare(undefined);
            });
    }

    return (
        <section className='share-content'>
            {loading ? (
                <div className='share-loading'>
                    <LoadingComponent />
                </div>
            ) : (
                <div className='share-data'>
                    <ShareComponent
                        shareData={shareData}
                        language={language}
                        translations={translations}
                        likedShare={likedShare}
                        setLikedShare={setLikedShare}
                        savedShare={savedShare}
                        setSavedShare={setSavedShare}
                    />
                </div>
            )}
        </section>
    );
}

export default Share;
