import React, { useState, useEffect } from 'react';
import Modal from '../../../components/shares-modal';
import '../../../style/private/NewShare/References.css';

function NewShareReferences({ data, onConfirm, counterClothes, counterAccessories, counterFootwear, translations }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContentType, setModalContentType] = useState('clothes');

    useEffect(() => {
        if (modalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [modalOpen]);

    const handleConfirm = (referenceURLs) => {
        onConfirm(modalContentType, referenceURLs);
        setModalOpen(false);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const openModal = (contentType) => {
        setModalContentType(contentType);
        setModalOpen(true);
    };

    return (
        <section className='newShare-references-content'>
            <div className='newShare-references-mainTitle'>
                <h6>{translations.private_newShare_referencesTitle}</h6>
            </div>

            <div className='newShare-references-subTitle'>
                <span>{translations.private_newShare_referencesSubtitle}</span>
            </div>

            <div className='newShare-references-actions-wrapper'>
                <div className={counterClothes > 0 ? ('newShare-references-actions-active') : ('newShare-references-actions')}>
                    <span>{translations.private_newShare_clothes}</span>
                    <button onClick={() => openModal('clothes')}>{translations.private_newShare_addReferences} <p>({counterClothes})</p></button>
                </div>
                <div className={counterAccessories > 0 ? ('newShare-references-actions-active') : ('newShare-references-actions')}>
                    <span>{translations.private_newShare_accessories}</span>
                    <button onClick={() => openModal('accessories')}>{translations.private_newShare_addReferences} <p>({counterAccessories})</p></button>
                </div>
                <div className={counterFootwear > 0 ? ('newShare-references-actions-active') : ('newShare-references-actions')}>
                    <span>{translations.private_newShare_footwear}</span>
                    <button onClick={() => openModal('footwear')}>{translations.private_newShare_addReferences} <p>({counterFootwear})</p></button>
                </div>
            </div>

            {modalOpen && (
                <Modal
                    contentType={modalContentType}
                    initialData={data[modalContentType]}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}

        </section>
    );
}

export default NewShareReferences;
