import React from 'react';
import '../../../style/private/EditShare/Properties.css';

function EditShareProperties({
    targetGender,
    setTargetGender,
    targetStyle,
    setTargetStyle,
    targetCopyrights,
    setTargetCopyrights,
    translations
}) {
    
    const handleStyleChange = (event) => {
        setTargetStyle(event.target.value);
    };

    const stylesOptions = [
        { value: 'casual', label: translations.private_editShare_styleCasual },
        { value: 'formal', label: translations.private_editShare_styleFormal },
        { value: 'sporty', label: translations.private_editShare_styleSporty },
        { value: 'elegant', label: translations.private_editShare_styleElegant },
        { value: 'vintage', label: translations.private_editShare_styleVintage }
    ];

    return (
        <section className='editShare-properties-content'>
            <div className='editShare-properties-mainTitle'>
                <h6>{translations.private_editShare_propertiesOfShare}</h6>
            </div>

            <div className='editShare-properties-vertical-wrapper'>
                <div className='editShare-properties-horizontal-wrapper'>
                    <span>{translations.private_editShare_targetGender}</span>
                    <div className='editShare-properties-horizontal-vertical-wrapper'>
                        <button className={targetGender === 'man' ? ('editShare-properties-targetGender-active') : ('editShare-properties-targetGender-not-active')} onClick={() => setTargetGender('man')}> {translations.private_editShare_genderMale}</button>
                        <button className={targetGender === 'woman' ? ('editShare-properties-targetGender-active') : ('editShare-properties-targetGender-not-active')} onClick={() => setTargetGender('woman')}>{translations.private_editShare_genderFemale}</button>
                    </div>
                </div>
                <div className='editShare-properties-horizontal-wrapper'>
                    <span>{translations.private_editShare_targetStyle}</span>
                    <div className='editShare-properties-horizontal-select-container'>
                        <select
                            className='editShare-properties-horizontal-select'
                            value={targetStyle}
                            onChange={handleStyleChange}
                        >
                            <option value="" disabled>{translations.private_editShare_chooseTargetStyle}</option>
                            {stylesOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='editShare-properties-vertical-wrapper'>
                <div className='editShare-properties-horizontal-wrapper'>
                    <span>{translations.private_editShare_copyrights}</span>
                    <div className='editShare-properties-horizontal-vertical-wrapper-input'>
                        <div className="editShare-properties-horizontal-vertical-input-container">
                            <span className="editShare-properties-horizontal-vertical-url-prefix">https://</span>
                            <input
                                type="text"
                                className="editShare-properties-horizontal-vertical-url-input"
                                placeholder={translations.private_editShare_urlDummy}
                                value={targetCopyrights}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if (value.startsWith('http://')) {
                                        value = value.substring(7);
                                    } else if (value.startsWith('https://')) {
                                        value = value.substring(8);
                                    }

                                    setTargetCopyrights(value);
                                }}
                            />
                        </div>

                        <p>{translations.private_editShare_leaveItBlankIfOwnerShipCopyrights}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EditShareProperties;