import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section1_Hero.css';

function Section1_Hero() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='section1_hero-content'>
            <div className='section1_hero-content-wrapper'>
                <div className='section1_hero-content-wrapper-leftSide'>
                    <div className='section1_hero-mainTitle'>
                        <h1>{translations.landingPage_section1_evelateYourStyle}</h1>
                        <h2>{translations.landingPage_section1_discoverShareAndInspire}</h2>
                    </div>
                    <div className='section1_hero-subTitle'>
                        <h2>{translations.landingPage_section1_findPerfectOutfitPieces}</h2>
                    </div>
                    <div className='section1_hero_startNow-wrapper'>
                        <h3>{translations.landingPage_section1_startToday}</h3>
                        <div className='section1_hero_startNow'>
                            <a href={`/${language}/mural`}><button>{translations.landingPage_section1_collections}</button></a>
                        </div>
                    </div>
                </div>
                <div className='section1_hero-content-wrapper-rightSide'>
                    <img src='/landingPage/hero.png' alt='Hero Dashboard' />
                </div>
            </div>
        </section>
    );
}

export default Section1_Hero;