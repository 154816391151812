import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import LoadingComponent from '../../components/share-loading';
import MainContext from '../../context/MainContext';
import Image from './EditShare/Image';
import Properties from './EditShare/Properties';
import References from './EditShare/References';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/EditShare.css';

function EditShare() {
    const navigate = useNavigate();
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession, localStoredCreator } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const location = useLocation();
    const { shareData } = location.state || {};

    const [error, setError] = useState('');
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (shareData) {
            updateDataFromShareData('clothes', shareData.clothing.items);
            updateDataFromShareData('accessories', shareData.accessories.items);
            updateDataFromShareData('footwear', shareData.footwear.items);

            setLoadingPage(false);
        }
    }, [shareData]);
    /* eslint-disable react-hooks/exhaustive-deps */

    //To delete variable image, since the information is already coming from the shareData
    const image = shareData.share.share;
    const extension = shareData.share.extension;
    const [targetGender, setTargetGender] = useState((shareData.share.gender).toLowerCase());
    const [targetStyle, setTargetStyle] = useState((shareData.share.style).toLowerCase());
    const [targetCopyrights, setTargetCopyrights] = useState(shareData.share.copyrights);

    const [data, setData] = useState({
        clothes: [],
        accessories: [],
        footwear: []
    });
    const [counterClothes, setCounterClothes] = useState(shareData.clothing.count);
    const [counterAccessories, setCounterAccessories] = useState(shareData.accessories.count);
    const [counterFootwear, setCounterFootwear] = useState(shareData.footwear.count);

    const updateDataFromShareData = (contentType, items) => {
        const referenceURLs = Object.entries(items)
            .filter(([key, url]) => url.trim() !== '')
            .reduce((acc, [key, url]) => {
                acc[key] = url;
                return acc;
            }, {});

        receiveReferences(contentType, referenceURLs);
    };

    // Receives the References from the React Fragment
    const receiveReferences = (contentType, referenceURLs) => {
        const updatedData = Object.entries(referenceURLs)
            .filter(([iconName, url]) => url.trim() !== '')
            .map(([iconName, url]) => ({ iconName, url }));

        setData(prevData => ({
            ...prevData,
            [contentType]: updatedData
        }));

        // Update the counters based on content type
        if (contentType === 'clothes') {
            setCounterClothes(updatedData.length);
        } else if (contentType === 'accessories') {
            setCounterAccessories(updatedData.length);
        } else if (contentType === 'footwear') {
            setCounterFootwear(updatedData.length);
        }
    };

    const updateReferencesFromShare = async () => {
        setLoadingButton(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/share`;

        const formData = new URLSearchParams({
            picture: image,
            gender: targetGender,
            style: targetStyle,
            copyrights: targetCopyrights,
            clothes: JSON.stringify(data.clothes),
            accessories: JSON.stringify(data.accessories),
            footwear: JSON.stringify(data.footwear),
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (response.status === 403) {
                    setLoadingButton(false);
                    logout();
                } else if (!response.ok) {
                    setLoadingButton(false);
                    setError(translations.private_editShare_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoadingButton(false);
                navigate(`/${language}/${localStoredUsername}/share/${image}`);
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    };

    const isButtonDisabled = !(image &&
        targetGender !== '' &&
        targetStyle !== '' &&
        (counterClothes !== 0 || counterAccessories !== 0 || counterFootwear !== 0)
    );

    return (
        <section className='editShare-content'>
            {loadingPage ? (
                <LoadingComponent />
            ) : (
                <>
                    <div className='editShare-vertical-divider'>
                        <Image
                            image={image}
                            extension={extension}
                            username={localStoredUsername}
                        />
                    </div>
                    <div className='editShare-vertical-divider'>
                        <div className='editShare-horizontal-divider'>
                            <div className='editShare-divider-return'>
                                <a href={`/${language}/${localStoredUsername}/share/${image}`}>
                                    <img src='/common/icon-return-arrow.png' alt='Return' />
                                </a>
                            </div>
                            <Properties
                                targetGender={targetGender}
                                setTargetGender={setTargetGender}
                                targetStyle={targetStyle}
                                setTargetStyle={setTargetStyle}
                                targetCopyrights={targetCopyrights}
                                setTargetCopyrights={setTargetCopyrights}
                                localStoredCreator={localStoredCreator}
                                translations={translations}
                            />
                        </div>
                        <div className='editShare-horizontal-divider'>
                            <References
                                data={data}
                                onConfirm={receiveReferences}
                                counterClothes={counterClothes}
                                counterAccessories={counterAccessories}
                                counterFootwear={counterFootwear}
                                translations={translations}
                            />
                        </div>

                        <div className='editShare-content-actions'>
                            {error !== '' && (
                                <div className='myAccount-personalInformation-error'>
                                    <span>{error}</span>
                                </div>
                            )}

                            <button type='submit' className='editShare-content-button-submit' onClick={updateReferencesFromShare} disabled={isButtonDisabled}>
                                {loadingButton ? (
                                    <img src='/loaders/icon-loading.png' alt='' />
                                ) : (
                                    <>
                                        {translations.private_editShare_editShare}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}

export default EditShare;
