import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { useShares } from '../../context/SharesContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import MainContext from '../../context/MainContext';
import MuralFiltersPublic from '../../components/muralFilters-public'
import Loading from '../../components/shares-loading';
import TrendyLooks from '../../components/trendy-looks';
import Gallery from '../../components/gallery';
import '../../style/private/MuralShares.css';

function MuralShares() {
    const { getSessionInfo } = useAuth();
    const { localStoredGender } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const { sharesData, setSharesData, page, setPage, index, setIndex, loadedSharesCache, setLoadedSharesCache, end, setEnd } = useShares();
    const [trendyLooks, setTrendyLooks] = useState([]);

    const [targetGender, setTargetGender] = useState(localStoredGender);
    const [targetStyle, setTargetStyle] = useState('all');
    const [valuesChanged, setValuesChanged] = useState(false);

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingShares, setLoadingShares] = useState(false);

    const [noShares, setNoShares] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoadingShares(true);
        loadNextShares();
        loadTrendyLooks();
    }, [targetGender, targetStyle]);
    /* eslint-enable react-hooks/exhaustive-deps */

    async function loadTrendyLooks() {
        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/fetch/trendy-looks`;

        const data = new URLSearchParams({
            gender: targetGender,
            style: targetStyle,
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setTrendyLooks(data.trendyLooks);
            })
            .catch(error => {
            });
    }

    async function loadNextShares() {
        setLoadingShares(true);

        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/fetch/all`;

        let queryParams;

        if (valuesChanged) {
            setValuesChanged(false);
            setPage(1);
            setIndex(1);
            setSharesData([]);
            setLoadedSharesCache([]);
            setTrendyLooks([]);

            queryParams = new URLSearchParams({
                page: 1,
                index: 1,
                gender: targetGender,
                style: targetStyle,
            });
        } else {
            queryParams = new URLSearchParams({
                page: page,
                index: index,
                gender: targetGender,
                style: targetStyle,
            });
        }

        try {
            const response = await fetch(`${baseURL}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.end && !data.sharesAvailable) {
                setNoShares(true);
            } else {
                setNoShares(false);
                setSharesData(prevSharesData => [...prevSharesData, ...data.shares]);
                setIndex(data.index);
                setEnd(data.end);
                setLoadedSharesCache(prevLoadedSharesCache => [...prevLoadedSharesCache, ...data.shares]);
                setPage(prevPage => prevPage + 1);
            }
        } catch (error) {
            console.error('Failed to load shares:', error);
            setNoShares(true);
        } finally {
            setLoading(false);
            setLoadingShares(false);
        }
    }


    return (
        <section className='muralShares-content'>
            <div className='muralShares-main'>
                <div className='muralShares-topSide'>
                    <div className='muralShares-main-leftSide'>
                        <h1>Mural of Shares</h1>
                    </div>
                    <div className='muralShares-main-rightSide' onClick={() => setFiltersOpen(true)}>
                        <span>Filter</span>
                    </div>
                </div>
                <div className='muralShares-bottomSide'>
                    <div className='muralShares-shares'>
                        {!loading && (
                            <div className='allShares-trendyLooks'>
                                <TrendyLooks
                                    language={language}
                                    translations={translations}
                                    trendyLooks={trendyLooks}
                                />
                            </div>
                        )}

                        {loading ? (
                            <div className='allShares-loading'>
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <div className='allShares-gallery'>
                                    <Gallery
                                        shares={loadedSharesCache}
                                        sharess={sharesData}
                                        loadNextShares={loadNextShares}
                                        loadingShares={loadingShares}
                                        noShares={noShares}
                                        endShares={end}
                                        language={language}
                                        translations={translations}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {filtersOpen && (
                <MuralFiltersPublic
                    setFiltersOpen={setFiltersOpen}
                    targetGender={targetGender}
                    setTargetGender={setTargetGender}
                    targetStyle={targetStyle}
                    setTargetStyle={setTargetStyle}
                    setValuesChanged={setValuesChanged}
                />
            )}
        </section >
    );
}

export default MuralShares;
