import React, { useState, useEffect, useRef, useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { useAuth } from '../../../context/AuthContext';
import MainContext from '../../../context/MainContext'
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/private/MyAccount/Profile.css';

function MyAccountProfile() {
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const [notification, setNotification] = useState('');
    const notificationTimeoutRef = useRef(null);
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loadingInfo, setLoadingInfo] = useState(true);

    const [username, setUsername] = useState('');
    const [usernameURL, setUsernameURL] = useState('');
    const [profilePicture, setProfilePicture] = useState(localStorage.getItem('picture'));
    const [usernameError, setUsernameError] = useState(false);
    const [loadingPicture, setLoadingPicture] = useState(false);
    const [loadingUsername, setLoadingUsername] = useState(false);

    const [referralCode, setReferralCode] = useState('');

    const fileInputRef = React.createRef();

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoadingInfo(true);
        fetchUserInformation();
        getUserReferralCode();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function fetchUserInformation() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/profile-information`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403) {
                        logout();
                    }
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setUsername(data.username);
                setUsernameURL(data.username);
                setProfilePicture(data.picture);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const changesUsername = () => {
        setLoadingUsername(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/username`;

        const formData = new URLSearchParams({
            newUsername: username.toLocaleLowerCase(),
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (response.status === 401) {
                    setUsernameError(translations.private_account_profile_usernameTaken);
                    return setLoadingUsername(false);
                } else if (!response.ok) {
                    setLoadingUsername(false);
                    setUsernameError(translations.private_account_profile_errorChangingUsername);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    localStorage.setItem('username', username.toLocaleLowerCase());
                    window.location.href = '';
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    function blobURLtoBlob(blobURL) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Failed to convert Blob URL to Blob object. Status: ${xhr.status}`));
                }
            };

            xhr.onerror = () => {
                reject(new Error('Failed to convert Blob URL to Blob object. Network error.'));
            };

            xhr.open('GET', blobURL);
            xhr.send();
        });
    }

    const changeProfilePicture = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

            if (!validImageTypes.includes(fileType)) {
                alert(translations.private_account_profile_wrongPictureUpload);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setLoadingPicture(true);

            const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/picture`;

            // Convert the Blob URL to a Blob
            blobURLtoBlob(imageUrl)
                .then(blob => {
                    // Create FormData and append the Blob
                    const formData = new FormData();
                    formData.append('image', blob, file.name);

                    console.log(blob);

                    // Send the FormData in a POST request
                    fetch(baseURL, {
                        method: 'POST',
                        headers: {
                            'email': localStoredEmail,
                            'username': localStoredUsername,
                            'session': localStoredSession
                        },
                        credentials: 'include',
                        body: formData,
                    })
                        .then(response => {
                            if (!response.ok) {
                                return Promise.reject({ status: response.status, statusText: response.statusText });
                            }
                            return response.json();
                        })
                        .then(data => {
                            setProfilePicture(data.picture);
                            localStorage.setItem('picture', data.picture);
                            window.location.href = '';
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    async function getUserReferralCode() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/referral-code`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (response.status === 403) {
                    logout(language);
                } else if (!response.ok) {
                    setLoadingInfo(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoadingInfo(false);
                setReferralCode(data.referralCode);
            })
            .catch(error => {
                setLoadingInfo(false);
            });
    }

    useEffect(() => {
        // Clear notification after 3 seconds
        if (notificationTimeoutRef.current) {
            clearTimeout(notificationTimeoutRef.current);
        }

        if (notification) {
            notificationTimeoutRef.current = setTimeout(() => {
                setNotification('');
            }, 3000);
        }
    }, [notification]);

    const handleCopyClick = () => {
        const stringToCopy = `https://www.matchthelook.com/@${usernameURL}`;

        if (navigator.clipboard && navigator.clipboard.writeText) {
            // Using the Clipboard API if available
            navigator.clipboard.writeText(stringToCopy).then(() => {
                setNotification(translations.private_account_profile_urlCopiedToClipboard);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method if Clipboard API is not supported
            const textArea = document.createElement("textarea");
            textArea.value = stringToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setNotification(translations.private_account_profile_urlCopiedToClipboard);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    const handleCopyClickReferral = () => {
        const stringToCopy = `https://www.matchthelook.com/${language}/${localStoredUsername}/referral/${referralCode}`;

        if (navigator.clipboard && navigator.clipboard.writeText) {
            // Using the Clipboard API if available
            navigator.clipboard.writeText(stringToCopy).then(() => {
                setNotification('');
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method if Clipboard API is not supported
            const textArea = document.createElement("textarea");
            textArea.value = stringToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setNotification('');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <section className='myAccount-profile-content'>

            <div className='myAccount-border-descriptor'>
                <h3>{translations.private_account_profile_title}</h3>
                <p>{translations.private_account_profile_subTitle}</p>
            </div>

            <div className='myAccount-border-content'>

                {loadingInfo ? (
                    <div className='myAccount-personalInformation-loading'>
                        <img src='/loaders/icon-loading.png' alt='Loading' />
                    </div>
                ) : (
                    <>
                        <label>{translations.private_account_profile_yourPublicProfile}</label>
                        <div className='myAccount-profile-publicProfile-wrapper'>
                            <div className="myAccount-profile-url-input-container">
                                <span className="myAccount-profile-url-prefix">https://</span>
                                <input
                                    type="text"
                                    className="myAccount-profile-url-input"
                                    placeholder={`www.matchthelook.com/@${localStoredUsername}`}
                                    value={`www.matchthelook.com/@${localStoredUsername}`}
                                    disabled
                                />
                                <button onClick={handleCopyClick}>{translations.private_account_profile_copyButton}</button>
                            </div>
                            {notification && (
                                <div className="myAccount-profile-url-input-container-notification">{notification}</div>
                            )}
                        </div>

                        <label>{translations.private_account_profile_profilePicture}</label>
                        <div className='myAccount-profile-image-wrapper'>
                            {profilePicture === null ? (
                                <img src='/common/icon-default-picture.png' alt='Default Profile' />
                            ) : (
                                <img src={`${MainContext.profilePictureURL}${profilePicture}`} alt='' />
                            )}
                            {loadingPicture ? (
                                <img src='/loaders/icon-loading.png' alt='' className='myAccount-profile-loader' />

                            ) : (
                                <button onClick={handleButtonClick}>{translations.private_account_profile_changeButton}</button>
                            )}
                            <input
                                type='file'
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={changeProfilePicture}
                                accept='.jpeg, .jpg, .png'
                            />

                        </div>

                        <label>{translations.private_account_profile_usernameTitle}</label>
                        <div className='myAccount-profile-username-wrapper'>
                            <input
                                type="text"
                                className={usernameError ? ('myAccount-profile-username-wrapper-input-error') : ('myAccount-profile-username-wrapper-input')}
                                placeholder={translations.private_account_profile_yourUsername}
                                value={username.toLowerCase()}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            {loadingUsername ? (
                                <img src='/loaders/icon-loading.png' alt='' className='myAccount-profile-loader' />

                            ) : (
                                <button onClick={changesUsername}>{translations.private_account_profile_changeButton}</button>
                            )}
                        </div>
                        {usernameError && (
                            <p className='myAccount-border-content-p-error'>{usernameError}</p>
                        )}

                        <label>{translations.private_account_referralCode}</label>
                        <div className='myAccount-profile-publicProfile-wrapper'>
                            <div className="myAccount-profile-url-input-container">
                                <span className="myAccount-profile-url-prefix">https://</span>
                                <input
                                    type="text"
                                    className="myAccount-profile-url-input"
                                    placeholder={`www.matchthelook.com/${language}/${localStoredUsername}/referral/${referralCode}`}
                                    value={`www.matchthelook.com/${language}/${localStoredUsername}/referral/${referralCode}`}
                                    disabled
                                />
                                <button onClick={handleCopyClickReferral}>{translations.private_account_profile_copyButton}</button>
                            </div>
                            {notification && (
                                <div className="myAccount-profile-url-input-container-notification">{notification}</div>
                            )}
                            <span>{translations.private_account_referralCode_description}</span>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}

export default MyAccountProfile;
