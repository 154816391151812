import React, { createContext, useContext, useState, useEffect } from 'react';
import MainContext from '../context/MainContext';

const AuthContext = createContext();

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidUsername = (username) => {
  return username.trim() !== '';
};

const isValidSessionToken = (sessionToken) => {
  return typeof sessionToken === 'string' && sessionToken.length >= 20;
};

export const AuthProvider = ({ children }) => {
  const localStoredEmail = localStorage.getItem('email');
  const localStoredUsername = localStorage.getItem('username');
  const localStoredSessionToken = localStorage.getItem('session');

  const userAuthenticated = Boolean(
    isValidEmail(localStoredEmail) &&
    isValidUsername(localStoredUsername) &&
    isValidSessionToken(localStoredSessionToken)
  );

  const [isAuthenticated, setIsAuthenticated] = useState(userAuthenticated);

  useEffect(() => {
    const handleStorageChange = () => {
      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const sessionToken = localStorage.getItem('session');
      setIsAuthenticated(
        Boolean(
          isValidEmail(email) &&
          isValidUsername(username) &&
          isValidSessionToken(sessionToken)
        )
      );
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function login(name, email, username, session, picture, gender) {
    if (isValidEmail(email) && isValidUsername(username) && isValidSessionToken(session)) {
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('username', username);
      localStorage.setItem('session', session);
      localStorage.setItem('picture', picture);
      localStorage.setItem('gender', gender);
      setIsAuthenticated(true);
    } else {
      console.error('Invalid login credentials');
    }
  }

  function getSessionInfo() {
    return {
      localStoredEmail: localStorage.getItem('email'),
      localStoredUsername: localStorage.getItem('username'),
      localStoredSession: localStorage.getItem('session'),
      localStoredGender: localStorage.getItem('gender'),
    };
  }

  function logout(language) {
    
    const formData = new URLSearchParams({
      email: localStorage.getItem('email'),
      session: localStorage.getItem('session'),
  });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (response.ok) {
          localStorage.removeItem('name');
          localStorage.removeItem('email');
          localStorage.removeItem('username');
          localStorage.removeItem('session');
          localStorage.removeItem('picture');
          localStorage.removeItem('gender');
          setIsAuthenticated(false);

          if(language === '' || language === undefined || language === null) {
            window.location.href = `/`;
          } else {
            window.location.href = `/${language}`;
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, getSessionInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};