import React from 'react';
import '../../../style/private/EditShare/Image.css';
import MainContext from '../../../context/MainContext';

function EditShareImage({ image, extension, username }) {

    return (
        <section className='editShare-image-content'>

            <div className='editShare-uploadImage-container'>
                <img src={`${MainContext.storageURL}${image}${extension}`} alt={`${image} shared by ${username}`} />
            </div>


        </section >
    );
}

export default EditShareImage;
