import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section4_AdvantagesGeneral.css';

function Section4_AdvantagesGeneral() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='section4_advantagesGeneral-content'>
            <h4>{translations.landingPage_section4_whyJoinUs}</h4>
            <div className='section4_advantages-list-horizontal'>
                <div className='section4_advantage-item-horizontal'>
                    <div className='section4_icon'>🌟</div>
                    <p><strong>{translations.landingPage_section4_discoverInspiration}<p/></strong> {translations.landingPage_section4_discoverInspiration_cause}</p>
                </div>
                <div className='section4_advantage-item-horizontal'>
                    <div className='section4_icon'>💸</div>
                    <p><strong>{translations.landingPage_section4_saveTimeMoney}<p/></strong> {translations.landingPage_section4_saveTimeMoney_cause}</p>
                </div>
                <div className='section4_advantage-item-horizontal'>
                    <div className='section4_icon'>🎨</div>
                    <p><strong>{translations.landingPage_section4_personalyzedStyle}<p/></strong> {translations.landingPage_section4_personalyzedStyle_cause}</p>
                </div>
                <div className='section4_advantage-item-horizontal'>
                    <div className='section4_icon'>🚀</div>
                    <p><strong>{translations.landingPage_section4_exclusiveFeatures}<p/></strong> {translations.landingPage_section4_exclusiveFeatures_cause}</p>
                </div>
            </div>
            <div className='section4_cta-section'>
                <a href={`/${language}/auth/register`} className='section4_cta-button'>{translations.landingPage_section4_cta}</a>
            </div>
        </section>
    );
}

export default Section4_AdvantagesGeneral;
