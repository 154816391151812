import React from 'react';
import '../style/components/shares-loading.css';

function SharesLoading() {
    const placeholders = new Array(6).fill(0);

    return (
        <section className='shares-loading-wrapper'>
            <div className='gallery-container'>
                {placeholders.map((_, index) => (
                    <div key={index} className='shares-loading-item'>
                        <div className='shares-loading-image'></div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SharesLoading;
