import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import MainContext from '../context/MainContext';
import enTranslations from '../translations/en.json';
import ptTranslations from '../translations/pt.json';
import '../style/components/user-profile.css';

function MyProfile({ results, shareCount }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const noMoreToShares = results.control.isEnd

    const getSocialMediaLinks = () => {
        return Object.entries(results.socialMedia)
            .filter(([platform, url]) => url && url.trim() && url !== 'null')
            .map(([platform, url], index) => (
                <a key={index} href={`https://${url}`} target='_blank' rel='noopener noreferrer' className='myProfile-social-media-link'>
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </a>
            ));
    };

    return (
        <section className='myProfile-content'>
            <div className='myProfile-header'>
                {results.user.picture === null || results.user.picture === undefined ? (
                    <img src='/common/icon-default-picture.png' alt='Default Profile' className='myProfile-picture' />
                ) : (
                    <img src={`${MainContext.profilePictureURL}${results.user.picture}`} alt={`${results.user.username}'s profile`} className='myProfile-picture' />
                )}
                <div className='myProfile-info'>
                    <h1 className='myProfile-username'>@{results.user.username}</h1>
                    <h2 className='myProfile-name'>{results.user.name}</h2>
                    <div className='myProfile-social-media'>
                        {getSocialMediaLinks()}
                    </div>
                </div>
            </div>
            <div className='myProfile-images'>
                <h3>{translations.shares}</h3>
                {shareCount > 0 ? (
                    <div className='myProfile-image-gallery'>
                        {shareCount > 0 && (
                            results.shares.map((share, index) => (
                                <a href={`/${language}/${results.user.username}/share/${share.picture}`} key={index}>
                                    <img src={`${MainContext.storageURL}${share.picture}${share.extension}`} alt={`Share ${index + 1}`} className='myProfile-image-item' />
                                </a>
                            ))
                        )}
                    </div>
                ) : (
                    <div className='myProfile-no-shares-message'>
                        <span>{translations.publicProfile_noShares}</span>
                    </div>
                )}
                {noMoreToShares && (
                    <div className='myProfile-button-loadMore'>
                        <button type='button' className='myProfile-button-loadMore'>
                            {translations.publicProfile_noShares}
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
}

export default MyProfile;
