import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import ReferralRegister from '../../pages/auth/ReferralRegister';

const Hooker_ReferralRegister = () => {
    const location = useLocation();
    const isPortuguese = location.pathname.startsWith('/pt');

    // SEO Content
    const pageTitle = isPortuguese ? 'Criar Conta' : 'Register';
    const pageDescription = isPortuguese ? 'Cria uma conta na Match the Look e começa a partilhar e inspirar outros utilizadores.' : 'Create an account on Match the Look to start sharing and getting inspired.';
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    return (
        <>
            <Helmet>
                {/* Dynamic Title */}
                <title>{pageTitle}</title>
                {/* Meta Tags */}
                <meta name="description" content={pageDescription} />
                <meta name="author" content="Match the Look" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://www.matchthelook.com/logo.png" />
                <meta property="og:url" content={canonicalUrl} />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content="https://www.matchthelook.com/logo.png" />

                {/* Canonical Link */}
                <link rel="canonical" href={canonicalUrl} />

                {/* Robots */}
                <meta name="robots" content="noindex, nofollow" />

                {/* Language (Dynamic) */}
                <html lang={isPortuguese ? 'pt-PT' : 'en'} />

                {/* Microsoft Clarity */}
                <script type="text/javascript">
                    {
                        `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                        `
                    }
                </script>

                {/* Google Aalytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {
                        `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                        `
                    }
                </script>

                {/* Google Tags */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16482386280"></script>
                <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-16482386280');`}</script>
            </Helmet>
            <section className='common-public-wrapper'>
                <div className='common-public-body'>
                    <React.Fragment>
                        <ReferralRegister />
                    </React.Fragment>
                </div>
            </section>
        </>
    );
};

export default Hooker_ReferralRegister;
