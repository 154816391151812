import React from 'react';
import '../../style/private/Dashboard.css';

function Dashboard() {
    return (
        <section className='dashboard-content'>

        </section>
    );
}

export default Dashboard;
