import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import MainContext from '../../../context/MainContext';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/private/MyAccount/SocialMedia.css';

function MyAccountProfile() {
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [tiktok, setTikTok] = useState('');
    const [youtube, setYoutube] = useState('');
    const [other, setOther] = useState('');

    const [facebookValidationMessage, setFacebookValidationMessage] = useState('');
    const [instagramValidationMessage, setInstagramValidationMessage] = useState('');
    const [linkedinValidationMessage, setLinkedinValidationMessage] = useState('');
    const [tiktokValidationMessage, setTikTokValidationMessage] = useState('');
    const [youtubeValidationMessage, setYoutubeValidationMessage] = useState('');
    const [otherValidationMessage, setOtherValidationMessage] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchUserSocialMedia();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function fetchUserSocialMedia() {
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/social-media`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403) {
                        logout(language);
                    }
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setFacebook(data.facebook);
                setInstagram(data.instagram);
                setLinkedin(data.linkedin);
                setTikTok(data.tiktok);
                setYoutube(data.youtube);
                setOther(data.other);
                setLoadingInfo(false);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const updateUserInformation = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/social-media`;

        const formData = new URLSearchParams({
            facebook: facebook,
            instagram: instagram,
            linkedin: linkedin,
            tiktok: tiktok,
            youtube: youtube,
            other: other,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                console.log(response.status);
                if (!response.ok) {
                    setLoading(false);
                    setError(translations.private_account_socialMedia_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                setSuccess(translations.private_account_socialMedia_informationUpdatedSuccess);
            })
            .catch(error => {
                console.error('Error creating account:', error);

            });
    };

    const isValidURL = (string) => {
        const regex = /^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9._-]+)*\/?$/;
        return regex.test(string);
    };    

    const handleFacebookChange = (e) => {
        let value = e.target.value.trim();
        
        // Remove protocol if present
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        
        setFacebook(value);
    
        if (value && !isValidURL(value)) {
            setFacebookValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setFacebookValidationMessage('');
        }
    };
    
    
    const handleInstagramChange = (e) => {
        let value = e.target.value.trim();
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        setInstagram(value);
    
        if (value && !isValidURL(value)) {
            setInstagramValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setInstagramValidationMessage('');
        }
    };
    
    const handleLinkedinChange = (e) => {
        let value = e.target.value.trim();
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        setLinkedin(value);
    
        if (value && !isValidURL(value)) {
            setLinkedinValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setLinkedinValidationMessage('');
        }
    };
    
    const handleTikTokChange = (e) => {
        let value = e.target.value.trim();
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        setTikTok(value);
    
        if (value && !isValidURL(value)) {
            setTikTokValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setTikTokValidationMessage('');
        }
    };
    
    const handleYoutubeChange = (e) => {
        let value = e.target.value.trim();
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        setYoutube(value);
    
        if (value && !isValidURL(value)) {
            setYoutubeValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setYoutubeValidationMessage('');
        }
    };
    
    const handleOtherChange = (e) => {
        let value = e.target.value.trim();
        if (value.startsWith('https://')) {
            value = value.substring(8);
        } else if (value.startsWith('http://')) {
            value = value.substring(7);
        }
        setOther(value);
    
        if (value && !isValidURL(value)) {
            setOtherValidationMessage(translations.private_account_socialMedia_URLNotValidAndWillNotBeUpdated);
        } else {
            setOtherValidationMessage('');
        }
    };
    

    return (
        <section className='myAccount-socialMedia-content'>

            <div className='myAccount-border-descriptor'>
                <h3>{translations.private_account_socialMedia_mainTitle}</h3>
                <p>{translations.private_account_socialMedia_subTitle}</p>
            </div>

            <div className='myAccount-border-content'>

                {loadingInfo ? (
                    <div className='myAccount-personalInformation-loading'>
                        <img src='/loaders/icon-loading.png' alt='' />
                    </div>
                ) : (
                    <form onSubmit={updateUserInformation}>
                        <div className='myAccount-socialMedia-divider'>
                            <div className='myAccount-socialMedia-container'>
                                <label>Facebook</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                <div className={!facebookValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!facebookValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!facebookValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={facebook !== null ? facebook : 'www.facebook.com/'}
                                            value={facebook}
                                            onChange={handleFacebookChange}
                                        />
                                    </div>
                                    {facebookValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{facebookValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                            <div className='myAccount-socialMedia-container'>
                                <label>Instagram</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                <div className={!instagramValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!instagramValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!instagramValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={instagram !== null ? instagram : 'www.instagram.com/'}
                                            value={instagram}
                                            onChange={handleInstagramChange}
                                        />
                                    </div>
                                    {instagramValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{instagramValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='myAccount-socialMedia-divider'>
                            <div className='myAccount-socialMedia-container'>
                                <label>Linkedin</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                <div className={!linkedinValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!linkedinValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!linkedinValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={linkedin !== null ? linkedin : 'www.linkedin.com/'}
                                            value={linkedin}
                                            onChange={handleLinkedinChange}
                                        />
                                    </div>
                                    {linkedinValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{linkedinValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                            <div className='myAccount-socialMedia-container'>
                                <label>Tik Tok</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                <div className={!tiktokValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!tiktokValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!tiktokValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={tiktok !== null ? tiktok : 'www.tiktok.com/'}
                                            value={tiktok}
                                            onChange={handleTikTokChange}
                                        />
                                    </div>
                                    {tiktokValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{tiktokValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='myAccount-socialMedia-divider'>
                            <div className='myAccount-socialMedia-container'>
                                <label>Youtube</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                <div className={!youtubeValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!youtubeValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!youtubeValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={youtube !== null ? youtube : 'www.youtube.com/'}
                                            value={youtube}
                                            onChange={handleYoutubeChange}
                                        />
                                    </div>
                                    {youtubeValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{youtubeValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                            <div className='myAccount-socialMedia-container'>
                                <label>Other Website</label>
                                <div className='myAccount-profile-socialMedia-wrapper'>
                                    <div className={!otherValidationMessage ? 'myAccount-profile-url-input-container' : 'myAccount-profile-url-input-container-error'}>
                                        <span className={!otherValidationMessage ? 'myAccount-profile-url-prefix' : 'myAccount-profile-url-prefix-error'}>https://</span>
                                        <input
                                            type="text"
                                            className={!otherValidationMessage ? 'myAccount-profile-url-input' : 'myAccount-profile-url-input-error'}
                                            placeholder={other !== null ? other : 'www.other.com/'}
                                            value={other}
                                            onChange={handleOtherChange}
                                        />
                                    </div>
                                    {otherValidationMessage && (
                                        <p className="myAccount-socialMedia-validation-message">{otherValidationMessage}</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {error !== '' && (
                            <div className='myAccount-socialMedia-error'>
                                <span>{error}</span>
                            </div>
                        )}

                        {success !== '' && (
                            <div className='myAccount-socialMedia-success'>
                                <span>{success}</span>
                            </div>
                        )}

                        <button type='submit' className='myAccount-socialMedia-button-submit'>
                            {loading ? (
                                <img src='/loaders/icon-loading.png' alt='' />
                            ) : (
                                <>
                                    {translations.private_account_socialMedia_update}
                                </>
                            )}
                        </button>
                    </form>
                )}

            </div>
        </section>
    );
}

export default MyAccountProfile;
