import React, { useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/Footer.css';

function Footer() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const { getSessionInfo, isAuthenticated } = useAuth();
    const { localStoredUsername } = getSessionInfo();

    return (
        <footer className='footer-content'>
            <div className='footer-container'>
                <div className='footer-section company-details'>
                    <div className='company-logo'>
                        <h2>{translations.common_mainTitleForCompany}</h2>
                    </div>
                    <div className='company-description'>
                        <p>{translations.footer_subtitle}</p>
                    </div>
                    <div className='social-media'>
                        <ul>
                            <li><a href='https://www.facebook.com/profile.php?id=61557251661638' target='_blank' rel='noopener noreferrer'><img src='/socials/icon-facebook-dark.png' alt='Facebook' /></a></li>
                            <li><a href='https://www.instagram.com/matchthelook_com' target='_blank' rel='noopener noreferrer'><img src='/socials/icon-instagram-dark.png' alt='Instagram' /></a></li>
                            <li><a href='https://www.linkedin.com/company/matchthelook/' target='_blank' rel='noopener noreferrer'><img src='/socials/icon-linkedin-dark.png' alt='Linkedin' /></a></li>
                        </ul>
                    </div>
                </div>

                <div className='footer-links'>
                    <div className='footer-section'>
                        <h3>{translations.footer_navigation}</h3>
                        <ul>
                            <li><a href={`/${language}/mural`}>{translations.footer_navigation_shares}</a></li>
                            <li><a href={`/${language}/partnerships`}>{translations.footer_partnerships}</a></li>
                            {isAuthenticated ? (
                                <li><a href={`/${language}/dashboard/${localStoredUsername}`}>{translations.footer_navigation_myaccount}</a></li>
                            ) : (
                                <li><a href={`/${language}/auth/login`}>{translations.footer_navigation_myaccount}</a></li>
                            )}
                        </ul>
                    </div>
                    <div className='footer-section'>
                        <h3>{translations.footer_policies}</h3>
                        <ul>
                            <li><a href='/terms'>{translations.footer_termsAndConditions}</a></li>
                            <li><a href='/cookies'>{translations.footer_cookiePolicy}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <p>&copy; {translations.footer_allRightsReserved}</p>
            </div>
        </footer>
    );
}

export default Footer;
