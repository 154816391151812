import React, { useState } from 'react';
import MainContext from '../context/MainContext';
import '../style/components/gallery.css';

function Gallery({ shares, loadNextShares, loadingShares, noShares, endShares, language, targetGender, translations }) {
    return (
        <section className='gallery-wrapper'>
            {noShares ? (
                <div className='gallery-noShares'>
                    <span>{translations.allShares_noSharesAvailable}</span>
                    <p>{translations.allShares_browseAll} <a href={`/${language}/shares/${targetGender}/all`}>{translations.allShares_here}</a>.</p>
                </div>
            ) : (
                <>
                    <div>
                        <div className='gallery-container'>
                            {shares.map((share, index) => (
                                <a href={`/${language}/${share.author}/share/${share.share}`} key={index}>
                                    <div className='gallery-item'>
                                        <ImageWithLoading
                                            src={`${MainContext.storageURL}${share.share}${share.extension}`}
                                            alt={`User ${share.author} shared ${share.share} for the collection ${share.category}`}
                                            translations={translations}
                                        />
                                    </div>
                                </a>
                            ))}
                        </div>

                        {!noShares && (
                            <div className='gallery-loadMore-shares-button'>
                                {loadingShares ? (
                                    <button><img src='/loaders/icon-loading.png' alt='Loading' /></button>
                                ) : (
                                    !endShares && (
                                        <button onClick={loadNextShares}>{translations.common_loadMore}</button>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
        </section>
    );
}

function ImageWithLoading({ src, alt, translations }) {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className='gallery-item-content'>
            {!isLoaded && (
                <div className='gallery-placeholder-loading'>
                    <img src='/loaders/icon-loading.png' alt='Loading' />
                </div>
            )}
            <img
                src={src}
                alt={alt}
                onLoad={() => setIsLoaded(true)}
                className={isLoaded ? ('gallery-image') : ('gallery-image-loading')}
            />
            {isLoaded && (
                <div className='gallery-info'>
                    <div className='gallery-info-content'>
                        <span>{translations.allShares_openShare}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Gallery;
