import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext'
import { CSRFTokenProvider } from './context/CSRFTokenContext';
import './style/components/common.css';
import './style/components/fonts.css';
import './style/components/forms.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider >
      <CSRFTokenProvider>
        <App />
      </CSRFTokenProvider>
    </AuthProvider>
  </React.StrictMode>
);