import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import Step1 from './Register/Step1';
import Step2 from './Register/Step2';
import Step3 from './Register/Step3';
import Step4 from './Register/Step4';
import '../../style/auth/Register.css';

function Register() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [step, setStep] = useState(1);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [errorOTPCode, setErrorOTPCode] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isStep1Complete = firstName && lastName && gender && birthday && country;
    const isStep2Complete = email && password && repeatPassword && !emailError && password === repeatPassword;

    const isStep3Complete = OTPCode && !errorOTPCode;

    // Function to validate password
    const isPasswordValid = (pwd) => {
        const minLength = 6;
        const passwordRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{${minLength},}$`);
        return passwordRegex.test(pwd);
    };

    useEffect(() => {
        if (password === repeatPassword) {
            setErrorPassword(false);
        } else if ((password !== repeatPassword || !isPasswordValid(password)) && (password !== '' || repeatPassword !== '')) {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }
    }, [password, repeatPassword]);


    function validateBeforeRegister() {
        if (password !== repeatPassword) {
            setErrorPassword(true);
        } else {
            setEmailError(false);
            setErrorPassword(false);
            verifyEmailAddress();
        }
    }

    const verifyEmailAddress = () => {
        setLoading(true);
        const data = new URLSearchParams({
            email: email.toLowerCase(),
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/verify-email?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    setEmailError(false);
                    setErrorMessage('');
                    registerUser();
                    return response.json();
                } else {
                    setLoading(false);
                    setEmailError(true);
                    setErrorMessage(translations.referralCode_userAlreadyRegister);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {

            })
            .catch(error => {
            });
    };

    const registerUser = () => {
        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/create-account`;

        const formData = new URLSearchParams({
            email: email,
            password: password,
            name: firstName,
            surname: lastName,
            gender: gender,
            country: country,
            birthday: birthday,
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setErrorMessage(translations.referralCode_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setStep(3);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error creating account:', error);
                setLoading(false);
                setErrorMessage(translations.referralCode_somethingWentWrong);
            });
    };

    const activateAccount = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/activate-account`;

        const formData = new URLSearchParams({
            email: email,
            securityToken: OTPCode
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setErrorMessage(translations.referralCode_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setErrorMessage('');
                    setStep(4);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
                setErrorMessage(translations.referralCode_somethingWentWrong);
            });
    };

    return (
        <section className='register-content'>

            <div className='register-leftSide-container'>
                <div className='register-leftSide-logo'>
                    <a href={`/${language}`}><h1>Match the Look</h1></a>
                </div>
                <div className='register-leftSide-action'>
                    <span>{translations.referralCode_alreadyHaveAccount}</span>
                    <a href={`/${language}/auth/login`}>{translations.referralCode_logIn}</a>
                </div>
                {step === 1 && (
                    <>
                        <Step1
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            gender={gender}
                            setGender={setGender}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            country={country}
                            setCountry={setCountry}
                            language={language}
                            MainContext={MainContext}
                            translations={translations}
                        />

                        <div className='register-leftSide-container-form-button'>
                            <button onClick={() => setStep(2)} disabled={!isStep1Complete}>{translations.referralCode_nextStep}</button>
                        </div>
                    </>
                )}

                {step === 2 && (
                    <>
                        <Step2
                            email={email}
                            setEmail={setEmail}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            password={password}
                            setPassword={setPassword}
                            repeatPassword={repeatPassword}
                            setRepeatPassword={setRepeatPassword}
                            errorPassword={errorPassword}
                            translations={translations}
                        />

                        {errorMessage !== '' && (
                            <div className='register-error-message'>
                                <span>{errorMessage}</span>
                            </div>
                        )}

                        <div className='register-leftSide-container-form-button'>
                            {loading ? (
                                <button disabled>
                                    <img src='/loaders/icon-loading.png' alt='Loading' />
                                </button>
                            ) : (
                                <button onClick={validateBeforeRegister} disabled={!isStep2Complete}>{translations.referralCode_createAccount}</button>
                            )}
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                        <Step3
                            email={email}
                            OTPCode={OTPCode}
                            setOTPCode={setOTPCode}
                            error={errorOTPCode}
                            setError={setErrorOTPCode}
                            translations={translations}
                        />

                        <div className='register-leftSide-container-form-button'>
                            {loading ? (
                                <button disabled>
                                    <img src='/loaders/icon-loading.png' alt='Loading' />
                                </button>
                            ) : (
                                <button onClick={activateAccount} disabled={!isStep3Complete}>{translations.referralCode_confirm}</button>
                            )}
                        </div>
                    </>
                )}

                {step === 4 && (
                    <>
                        <Step4
                            translations={translations}
                        />
                        <div className='register-leftSide-container-form-button'>
                            <a href={`/${language}/auth/login`}><button>{translations.referralCode_goToLogin}</button></a>
                        </div>
                    </>
                )}
            </div>
            <div className='register-rightSide-container'>
                <div className='register-rightSide-box-container'>
                    <div className='register-rightSide-box-profilePicture'>
                        <img src='/user.jpg' alt='User' />
                    </div>
                    <div className='register-rightSide-box-quote'>
                        <p><b>{translations.register_message1}</b></p><p> {translations.register_message2}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;
