import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section3_Share.css';

function Section3_Share() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='section3_share-content'>
            <div className='section3_share-content-leftSide'>
                <img src='/landingPage/share.png' alt='Share Exmaple' />
            </div>
            <div className='section3_share-content-rightSide'>
                <h4>{translations.landingPage_section3_mainTitle}</h4>
                <p>{translations.landingPage_section3_descriptor}</p>
            </div>
        </section>
    );
}

export default Section3_Share;
