import React, { useState, useRef, useEffect, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/Header.css';

function Header() {
    const { isAuthenticated, getSessionInfo } = useAuth();
    const { localStoredUsername } = getSessionInfo();
    const { language, toggleLanguage } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const isChecked = language === 'pt';

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    // Handle clicks outside of the dropdown menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(prev => {
                    document.body.classList.toggle('no-scroll', !prev);
                    return !prev;
                });
            }
        };

        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [menuOpen]);

    const toggleMenu = () => {
        setMenuOpen(prev => {
            document.body.classList.toggle('no-scroll', !prev);
            return !prev;
        });
        setIsDropdownOpen(false);
    };

    return (
        <section className='header-content'>
            <div className='header-leftSide-mobile'>
                <img src='/common/icon-menu.png' alt='Menu Options' onClick={toggleMenu} />
            </div>
            <div className='header-leftSide'>
                <p><a href={`/${language}/mural`}><b>{translations.header_collections}</b></a></p>
                <p><a href={`/${language}/partnerships`}>{translations.header_partnerships}</a></p>
            </div>
            <div className='header-middleSide'>
                <a href={`/${language}`}><h1>Match the Look</h1></a>
            </div>
            <div className='header-rightSide'>
                <div className='switch'>
                    <input
                        id='language-toggle'
                        className='check-toggle check-toggle-round-flat'
                        type='checkbox'
                        checked={isChecked}
                        onChange={() => toggleLanguage(language === 'en' ? 'pt' : 'en')}
                    />
                    <label htmlFor='language-toggle'></label>
                    <span className='on'>EN</span>
                    <span className='off'>PT</span>
                </div>
                {isAuthenticated ? (
                    <a href={`/${language}/dashboard/${localStoredUsername}`}><button>{translations.header_openDashboard}</button></a>
                ) : (
                    <a href={`/${language}/auth/login`}><button>{translations.header_signIn}</button></a>
                )}
            </div>
        </section>
    );
}

export default Header;
