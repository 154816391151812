import React, { useState, useEffect } from 'react';
import '../../../style/auth/Register/Step1.css';

function Register_Step1({ firstName, setFirstName, lastName, setLastName, gender, setGender, birthday, setBirthday, country, setCountry, language, MainContext, translations }) {
    const [countryOptions, setCountryOptions] = useState([]);

    const genderOptions = [
        { value: 'male', label: translations.referralCode_step1_genderMan },
        { value: 'female', label: translations.referralCode_step1_genderWoman },
    ];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCountryList();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const getCountryList = () => {
        const data = new URLSearchParams({
            language: language
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data);
                setCountryOptions(data.countries);
            })
            .catch(error => {

            });
    };

    const formatDate = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        let formattedValue = numericValue;

        if (numericValue.length >= 2) {
            formattedValue = numericValue.slice(0, 2) + '-' + numericValue.slice(2);
        }
        if (numericValue.length >= 4) {
            formattedValue = formattedValue.slice(0, 5) + '-' + numericValue.slice(4, 8);
        }

        setBirthday(formattedValue);
    };

    return (
        <section className='register-step1-content'>
            <div className='register-step1-leftSide-container'>
                <div className='register-step1-leftSide-container-form'>
                    <div className='register-step1-leftSide-container-form-title'>
                        <h2>{translations.referralCode_step1_SignUp}</h2>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.referralCode_step1_firstName}</label>
                            <input type="text" placeholder={translations.referralCode_step1_firstName} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.referralCode_step1_lastName}</label>
                            <input type="text" placeholder={translations.referralCode_step1_lastName} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.referralCode_step1_gender}</label>
                            <select
                                id="gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className='register-step1-leftSide-container-form-select'
                                required >
                                <option value="" disabled>{translations.referralCode_step1_selectYourGender}</option>
                                {genderOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label htmlFor='birthday'>{translations.referralCode_step1_birthday}</label>
                            <input
                                id='birthday'
                                type='text'
                                className='register-step1-leftSide-container-form-input-date'
                                placeholder='DD-MM-YYYY'
                                maxLength='10'
                                value={birthday}
                                onChange={(e) => formatDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.referralCode_step1_country}</label>
                            <select
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className='register-step1-leftSide-container-form-select'
                                required >
                                <option value="" disabled>{translations.referralCode_step1_selectYourCountry}</option>
                                {countryOptions.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.country}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register_Step1;
