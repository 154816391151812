import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import LandingPage from '../../pages/public/LandingPage';
import Footer from '../../pages/public/Footer';

const Hooker_Index = () => {
    const location = useLocation();
    const isPortuguese = location.pathname.startsWith('/pt');
    const pageTitle = isPortuguese ? 'Página Principal' : 'Main Page';

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Match the Look",
        "url": "https://www.matchthelook.com",
        "logo": "https://www.matchthelook.com/logo.png",
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Support",
            "email": "hello@matchthelook.com"
        }
    };

    return (
        <>
            <Helmet>
                {/* Primary Meta Tags */}
                <title>{pageTitle}</title>
                <meta name="description" content="Explore the best collections for men and women, tailored just for you." />
                <meta name="author" content="Match the Look" />
                <meta name="keywords" content="fashion inspiration, social network, fashion hub, style sharing, outfit ideas, clothing references, men’s fashion, women’s fashion" />
                <script type="application/ld+json">
                    {JSON.stringify(schemaMarkup)}
                </script>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content="Explore the best shares for men and women, tailored just for you." />
                <meta property="og:image" content="https://www.matchthelook.com/logo.png" />
                <meta property="og:url" content={window.location.href} />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content="Explore the best shares for men and women, tailored just for you." />
                <meta name="twitter:image" content="https://www.matchthelook.com/logo.png" />

                {/* Canonical Link */}
                <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />

                {/* Robots */}
                <meta name="robots" content="index, follow" />

                {/* Language (Dynamic) */}
                <html lang={navigator.language === 'pt-BR' ? 'pt' : 'en'} />

                {/* Microsoft Clarity */}
                <script type="text/javascript">
                    {
                        `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                        `
                    }
                </script>

                {/* Google Aalytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {
                        `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                        `
                    }
                </script>

                {/* Google Tags */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16482386280"></script>
                <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-16482386280');`}</script>
            </Helmet>
            <section className='common-public-wrapper'>
                <div className='common-public-body'>
                    <React.Fragment>
                        <LandingPage />
                    </React.Fragment>
                </div>
                <div className='common-public-footer'>
                    <React.Fragment>
                        <Footer />
                    </React.Fragment>
                </div>
            </section>
        </>
    );
};

export default Hooker_Index;
