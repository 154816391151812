import React from 'react';
import '../../../style/auth/Register/Step4.css';

function Register_Step4({ translations }) {
    
    return (
        <section className='register-step1-content'>
            <div className='register-step1-leftSide-container'>
                <div className='register-step1-leftSide-container-form'>
                    <div className='register-step1-leftSide-container-form-title'>
                        <h2>{translations.referralCode_step1_SignUp}</h2>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step3-leftSide-message-container'>
                            <span className='register-step3-leftSide-message-big'>
                                {translations.referralCode_step4_message1}
                            </span>
                            <span className='register-step3-leftSide-message-small'>
                                {translations.referralCode_step4_message2}
                            </span>
                            <span className='register-step3-leftSide-message-small'>
                                {translations.referralCode_step4_message3}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register_Step4;