import React from 'react';
import '../../../style/private/NewShare/Properties.css';

function NewShareProperties({
    targetGender,
    setTargetGender,
    targetStyle,
    setTargetStyle,
    targetCopyrights,
    setTargetCopyrights,
    translations,
}) {

    const stylesOptions = [
        { value: 'casual', label: translations.private_newShare_styleCasual },
        { value: 'formal', label: translations.private_newShare_styleFormal },
        { value: 'sporty', label: translations.private_newShare_styleSporty },
        { value: 'elegant', label: translations.private_newShare_styleElegant },
        { value: 'vintage', label: translations.private_newShare_styleVintage }
    ];

    const handleStyleChange = (event) => {
        setTargetStyle(event.target.value);
    };

    return (
        <section className='newShare-properties-content'>
            <div className='newShare-properties-mainTitle'>
                <h6>{translations.private_newShare_propertiesTitle}</h6>
            </div>

            <div className='newShare-properties-vertical-wrapper'>
                <div className='newShare-properties-horizontal-wrapper'>
                    <span>{translations.private_newShare_targetGender}</span>
                    <div className='newShare-properties-horizontal-vertical-wrapper'>
                        <button className={targetGender === 'man' ? ('newShare-properties-targetGender-active') : ('newShare-properties-targetGender-not-active')} onClick={() => setTargetGender('man')}> {translations.private_newShare_targetGender_man}</button>
                        <button className={targetGender === 'woman' ? ('newShare-properties-targetGender-active') : ('newShare-properties-targetGender-not-active')} onClick={() => setTargetGender('woman')}> {translations.private_newShare_targetGender_woman}</button>
                    </div>
                </div>
                <div className='newShare-properties-horizontal-wrapper'>
                    <span>{translations.private_newShare_targetStyle}</span>
                    <div className='newShare-properties-horizontal-select-container'>
                        <select
                            className='newShare-properties-horizontal-select'
                            value={targetStyle}
                            onChange={handleStyleChange}
                        >
                            <option value="" disabled>{translations.private_newShare_chooseStyle}</option>
                            {stylesOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='newShare-properties-vertical-wrapper'>
                <div className='newShare-properties-horizontal-wrapper'>
                    <span>{translations.private_newShare_copyrights}</span>
                    <div className='newShare-properties-horizontal-vertical-wrapper-input'>
                        <div className="newShare-properties-horizontal-vertical-input-container">
                            <span className="newShare-properties-horizontal-vertical-url-prefix">https://</span>
                            <input
                                type="text"
                                className="newShare-properties-horizontal-vertical-url-input"
                                placeholder={`www.example.com/`}
                                value={targetCopyrights}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if (value.startsWith('http://')) {
                                        value = value.substring(7);
                                    } else if (value.startsWith('https://')) {
                                        value = value.substring(8);
                                    }

                                    setTargetCopyrights(value);
                                }}
                            />
                        </div>

                        <p>{translations.private_newShare_copyrights_info}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewShareProperties;

