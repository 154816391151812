import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import MainContext from '../../context/MainContext'
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/UserPoints.css';

function ReferralCode_UserPoints() {
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loadingPoints, setLoadingPoints] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [currentPoints, SetCurrentPoints] = useState(0);
    const [pointsHistory, setPointsHistory] = useState([]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getUserPoints();
        getUserPointsHistory();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function getUserPoints() {
        setLoadingPoints(true);
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/points`;

        fetch(`${baseURL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if(response.status === 403) {
                    logout(language);
                } else if (!response.ok) {
                    setLoadingPoints(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoadingPoints(false);
                SetCurrentPoints(data.points);
            })
            .catch(error => {
                setLoadingPoints(false);
            });
    }

    async function getUserPointsHistory() {
        setLoadingHistory(true);
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/fetch/points/history`;

        const data = new URLSearchParams({
            language: language
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession,
            },
            credentials: 'include',
        })
            .then(response => {
                if(response.status === 403) {
                    logout(language);
                } else if (!response.ok) {
                    setLoadingHistory(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data);
                setLoadingHistory(false);
                setPointsHistory(data.history);
            })
            .catch(error => {
                setLoadingHistory(false);
            });
    }

    return (
        <section className='referralCode-userPoints-content'>
            <div className='referralCode-userPoints-myPoints'>
                <div className='referralCode-userPoints-upperTitle'>
                    <h2>{translations.private_userPoints_myPoints}</h2>
                </div>
                {loadingPoints ? (
                    <img src='/loaders/icon-loading.png' alt='' />
                ) : (
                    <h3>{currentPoints}</h3>
                )}
                <span>{translations.private_userPoints_points}</span>
            </div>

            <div className='referralCode-userPoints-belowTitle'>
                <h2>{translations.private_userPoints_awardedPoints}</h2>
            </div>

            <div className='referralCode-userPoints-tableWrapper'>
                {loadingHistory ? (
                    <>
                        <table className='referralCode-userPoints-table'>
                            <thead>
                                <tr>
                                    <th>{translations.private_userPoints_tableDate}</th>
                                    <th>{translations.private_userPoints_tableDescription}</th>
                                    <th>{translations.private_userPoints_tablePoints}</th>
                                </tr>
                            </thead>
                        </table>

                        <div className='referralCode-userPoints-table-loading'>
                            <img src='/loaders/icon-loading.png' alt='' />
                        </div>
                    </>
                ) : (
                    <table className='referralCode-userPoints-table'>
                        <thead>
                            <tr>
                            <th>{translations.private_userPoints_tableDate}</th>
                                    <th>{translations.private_userPoints_tableDescription}</th>
                                    <th>{translations.private_userPoints_tablePoints}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pointsHistory.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.date}</td>
                                    <td>{entry.description}</td>
                                    <td>{entry.points}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </section>
    );
}

export default ReferralCode_UserPoints;

