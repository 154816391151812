import React from 'react';
import '../style/components/userprofile-loading.css';

function ProfileLoading() {
    return (
        <section className='profile-loading-wrapper'>
            <div className='profile-loading-header'>
                <div className='profile-loading-picture-placeholder'></div>
                <div className='profile-loading-info'>
                    <div className='profile-loading-username-placeholder'></div>
                    <div className='profile-loading-name-placeholder'></div>
                    <div className='profile-social-loading-media-placeholder'>
                        <div className='profile-social-loading-media-link-placeholder'></div>
                        <div className='profile-social-loading-media-link-placeholder'></div>
                        <div className='profile-social-loading-media-link-placeholder'></div>
                    </div>
                </div>
            </div>
            <div className='profile-loading-images'>
                <div className='profile-image-loading-switch'>
                    <div className='profile-switch-loading-btn-placeholder'></div>
                    <div className='profile-switch-loading-btn-placeholder'></div>
                </div>
                <div className='profile-image-loading-gallery'>
                    {new Array(6).fill(0).map((_, index) => (
                        <div key={index} className='profile-image-loading-item-placeholder'></div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ProfileLoading;
