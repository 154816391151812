import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext'
import Image from './NewShare/Image';
import Properties from './NewShare/Properties';
import References from './NewShare/References';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/NewShare.css';

function NewShare() {
    const navigate = useNavigate();
    const { getSessionInfo, logout } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession, localStoredCreator } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);
    const imageRef = useRef(null);

    const [targetGender, setTargetGender] = useState('');
    const [targetStyle, setTargetStyle] = useState('');
    const [targetCopyrights, setTargetCopyrights] = useState('');

    const [data, setData] = useState({
        clothes: [],
        accessories: [],
        footwear: []
    });

    const [counterClothes, setCounterClothes] = useState(0);
    const [counterAccessories, setCounterAccessories] = useState(0);
    const [counterFootwear, setCounterFootwear] = useState(0);

    const isButtonDisabled = !(image &&
        targetGender !== '' &&
        targetStyle !== '' &&
        (counterClothes !== 0 || counterAccessories !== 0 || counterFootwear !== 0)
    );

    // Receives the Image from the Image React Fragment
    const handleImageSelection = (selectedImage) => {
        setImage(selectedImage);
        console.log(selectedImage);
    };

    // Receives the References from the React Fragment
    const receiveReferences = (contentType, referenceURLs) => {
        const updatedData = Object.entries(referenceURLs)
            .filter(([iconName, url]) => url.trim() !== '')
            .map(([iconName, url]) => ({ iconName, url }));

        setData(prevData => ({
            ...prevData,
            [contentType]: updatedData
        }));

        // Update the counters based on content type
        if (contentType === 'clothes') {
            setCounterClothes(updatedData.length);
        } else if (contentType === 'accessories') {
            setCounterAccessories(updatedData.length);
        } else if (contentType === 'footwear') {
            setCounterFootwear(updatedData.length);
        }
    };

    const uploadPictureToAzure = async () => {
        setLoading(true);

        let response;

        if (imageRef.current) {
            response = await imageRef.current.submitCroppedImage();
        }

        if (response.ok) {
            const blob = await response.blob(); // Convert the Blob URL to a Blob object

            const baseURL = `${MainContext.apiURL}/api/v1/private/shares/upload/image`;

            const formData = new FormData();
            formData.append('image', blob);
            formData.append('gender', targetGender);
            formData.append('style', targetStyle);
            formData.append('copyrights', targetCopyrights);

            fetch(baseURL, {
                method: 'POST',
                headers: {
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession
                },
                credentials: 'include',
                body: formData,
            })
                .then(response => {
                    if (response.status === 403) {
                        setLoading(false);
                        logout();
                    } else if (!response.ok) {
                        setLoading(false);
                        setError(translations.private_newShare_somethingWentWrong);
                        return Promise.reject({ status: response.status, statusText: response.statusText });
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    updateReferencesFromShare(data.picture);
                })
                .catch(error => {
                    console.error('Error creating account:', error);

                });
        }
    };

    const updateReferencesFromShare = async (pictureName) => {
        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/upload/references`;

        const formData = new URLSearchParams({
            picture: pictureName,
            clothes: JSON.stringify(data.clothes),
            accessories: JSON.stringify(data.accessories),
            footwear: JSON.stringify(data.footwear),
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (response.status === 403) {
                    setLoading(false);
                    logout();
                } else if (!response.ok) {
                    setLoading(false);
                    setError(translations.private_newShare_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                navigate(`/${language}/${localStoredUsername}/share/${pictureName}`);
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    };

    return (
        <section className='newShare-content'>
            <div className='newShare-vertical-divider'>
                <Image
                    onImageSelect={handleImageSelection}
                    ref={imageRef}
                    translations={translations}
                />
            </div>
            <div className='newShare-vertical-divider'>
                <div className='newShare-horizontal-divider'>
                    <div className='newShare-divider-return'>
                        <a href={`/${language}/dashboard/${localStoredUsername}`}><img src='/common/icon-return-arrow.png' alt='Return' /></a>
                    </div>
                    <Properties
                        targetGender={targetGender}
                        setTargetGender={setTargetGender}
                        targetStyle={targetStyle}
                        setTargetStyle={setTargetStyle}
                        targetCopyrights={targetCopyrights}
                        setTargetCopyrights={setTargetCopyrights}
                        translations={translations}
                        localStoredCreator={localStoredCreator}
                    />
                </div>
                <div className='newShare-horizontal-divider'>
                    <References
                        data={data}
                        onConfirm={receiveReferences}
                        counterClothes={counterClothes}
                        counterAccessories={counterAccessories}
                        counterFootwear={counterFootwear}
                        translations={translations}
                    />
                </div>

                <div className='newShare-content-actions'>
                    {error !== '' && (
                        <div className='myAccount-personalInformation-error'>
                            <span>{error}</span>
                        </div>
                    )}

                    <button type='submit' className='newShare-content-button-submit' onClick={uploadPictureToAzure} disabled={isButtonDisabled}>
                        {loading ? (
                            <img src='/loaders/icon-loading.png' alt='' />
                        ) : (
                            <>
                                {translations.private_newShare_createNewShare}
                            </>
                        )}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default NewShare;
