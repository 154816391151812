import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section2_Dashboard.css';

function Section2_Dashboard() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='section2_dashboard-content'>
            <div className='section2_dashboard-content-leftSide'>
                <h3>{translations.landingPage_section2_seeLatestTrendGlance}</h3>
                <div className='section2_dashboard-description'>
                    <p>{translations.landingPage_section2_descriptor}</p>
                </div>
                <div className='section2_dashboard-content-buttons-wrapper'>
                    <div className='section2_dashboard-content-cta'>
                        <a href={`/${language}/auth/register`}><button className='section2_dashboard-content-cta-button'>{translations.landingPage_section2_startYourJourney}</button></a>
                    </div>
                </div>
            </div>
            <div className='section2_dashboard-content-rightSide'>
                <div className='section2_dashboard-images'>
                    <img src='/landingPage/stack.png' alt='Dashboard 1' />
                </div>
            </div>
        </section>
    );
}

export default Section2_Dashboard;
