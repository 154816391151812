import React, { useEffect } from 'react';
import '../../../style/auth/Register/Step2.css';

function Register_Step1({ email, setPassword, setRepeatPassword, errorPassword, translations }) {

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkPasswordStrength();
        checkRepeatPasswordStrength();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    function checkPasswordStrength() {
        const password = document.getElementById('password-input').value;
        const bars = [
            document.getElementById('register-step2-bar1'),
            document.getElementById('register-step2-bar2'),
            document.getElementById('register-step2-bar3'),
            document.getElementById('register-step2-bar4')
        ];
        const message = document.getElementById('register-step2-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = 'red';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.fontWeight = '400';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';

        let strength = 0;
        if (password.length > 5) strength++;
        if (/[A-Z]/.test(password)) strength++;
        if (/[0-9]/.test(password)) strength++;
        if (/[@$!%*?&#]/.test(password)) strength++;

        if (password.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.referralCode_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.referralCode_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#FFD700'; // Yellow
                    bars[1].style.backgroundColor = '#FFD700';
                    message.textContent = translations.referralCode_step2_passwordOK;
                    message.style.color = '#FFD700';
                    message.style.fontWeight = '600';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = 'blue';
                    bars[1].style.backgroundColor = 'blue';
                    bars[2].style.backgroundColor = 'blue';
                    message.textContent = translations.referralCode_step2_passwordGood;
                    message.style.color = 'blue';
                    message.style.fontWeight = '600';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = 'green';
                    bars[1].style.backgroundColor = 'green';
                    bars[2].style.backgroundColor = 'green';
                    bars[3].style.backgroundColor = 'green';
                    message.textContent = translations.referralCode_step2_passwordStrong;
                    message.style.color = 'green';
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.referralCode_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontWeight = '400';
        }
    }

    function checkRepeatPasswordStrength() {
        const repeatPassword = document.getElementById('repeat-password-input').value;
        const bars = [
            document.getElementById('register-step2-repeat-bar1'),
            document.getElementById('register-step2-repeat-bar2'),
            document.getElementById('register-step2-repeat-bar3'),
            document.getElementById('register-step2-repeat-bar4')
        ];
        const message = document.getElementById('register-step2-repeat-password-strength-message');

        const defaultColor = '#ccc';
        const activeColor = 'red';

        bars.forEach(bar => {
            bar.style.backgroundColor = defaultColor;
        });
        message.className = '';
        message.style.color = defaultColor;
        message.style.fontSize = '0.8rem';
        message.style.fontWeight = '400';
        message.style.marginTop = '5px';
        message.style.textAlign = 'right';

        let strength = 0;
        if (repeatPassword.length > 5) strength++;
        if (/[A-Z]/.test(repeatPassword)) strength++;
        if (/[0-9]/.test(repeatPassword)) strength++;
        if (/[@$!%*?&#]/.test(repeatPassword)) strength++;

        if (repeatPassword.length > 0) {
            switch (strength) {
                case 0:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.referralCode_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 1:
                    bars[0].style.backgroundColor = activeColor;
                    message.textContent = translations.referralCode_step2_passwordTooWeak;
                    message.style.color = activeColor;
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-weak');
                    break;
                case 2:
                    bars[0].style.backgroundColor = '#FFD700'; // Yellow
                    bars[1].style.backgroundColor = '#FFD700';
                    message.textContent = translations.referralCode_step2_passwordOK;
                    message.style.color = '#FFD700';
                    message.style.fontWeight = '600';
                    message.classList.add('register-step2-password-ok');
                    break;
                case 3:
                    bars[0].style.backgroundColor = 'blue';
                    bars[1].style.backgroundColor = 'blue';
                    bars[2].style.backgroundColor = 'blue';
                    message.textContent = translations.referralCode_step2_passwordGood;
                    message.style.color = 'blue';
                    message.style.fontWeight = '600';
                    message.classList.add('register-step2-password-good');
                    break;
                case 4:
                    bars[0].style.backgroundColor = 'green';
                    bars[1].style.backgroundColor = 'green';
                    bars[2].style.backgroundColor = 'green';
                    bars[3].style.backgroundColor = 'green';
                    message.textContent = translations.referralCode_step2_passwordStrong;
                    message.style.color = 'green';
                    message.style.fontWeight = '700';
                    message.classList.add('register-step2-password-strong');
                    break;
                default:
                    break;
            }
        } else {
            message.textContent = translations.referralCode_step2_passwordTooWeak;
            message.style.color = defaultColor;
            message.style.fontWeight = '400';
        }
    }

    return (
        <section className='register-step1-content'>
            <div className='register-step1-leftSide-container'>
                <div className='register-step1-leftSide-container-form'>
                    <div className='register-step1-leftSide-container-form-title'>
                        <h2>{translations.forgotPassword_step1_forgotPassword}</h2>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.forgotPassword_step1_email}</label>
                            <input
                                type="text"
                                placeholder={translations.forgotPassword_step1_email}
                                value={email}
                                disabled
                            />
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className={errorPassword ? ('register-step2-leftSide-container-form-input-error') : ('register-step1-leftSide-container-form-input')}>
                            <label>{translations.login_step1_password}</label>
                            <input
                                type='password'
                                id='password-input'
                                placeholder={translations.login_step1_password}
                                onInput={checkPasswordStrength}
                                onChange={(e) => {
                                    setPassword(e.target.value);

                                }}
                            />
                            <div className='register-step2-password-strength-bars'>
                                <div className='register-step2-strength-bar' id='register-step2-bar1'></div>
                                <div className='register-step2-strength-bar' id='register-step2-bar2'></div>
                                <div className='register-step2-strength-bar' id='register-step2-bar3'></div>
                                <div className='register-step2-strength-bar' id='register-step2-bar4'></div>
                            </div>
                            <div id='register-step2-password-strength-message' className='register-step2-password-strength-message' />
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className={errorPassword ? ('register-step2-leftSide-container-form-input-error') : ('register-step1-leftSide-container-form-input')}>
                            <label>{translations.referralCode_step2_repeatPassword}</label>
                            <input
                                type='password'
                                id='repeat-password-input'
                                placeholder={translations.referralCode_step2_repeatPassword}
                                onInput={checkRepeatPasswordStrength}
                                onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                }}
                            />
                            <div className='register-step2-password-strength-bars'>
                                <div className='register-step2-strength-bar' id='register-step2-repeat-bar1'></div>
                                <div className='register-step2-strength-bar' id='register-step2-repeat-bar2'></div>
                                <div className='register-step2-strength-bar' id='register-step2-repeat-bar3'></div>
                                <div className='register-step2-strength-bar' id='register-step2-repeat-bar4'></div>
                            </div>
                            <div id='register-step2-repeat-password-strength-message' className='register-step2-password-strength-message' />
                        </div>
                        {errorPassword && (
                            <div className='register-step2-error-message'>
                                <span>{translations.referralCode_step2_passwordsDontMatch}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Register_Step1;