import React from 'react';
import '../style/components/confirmation-modal.css';

function SharesModal({ onConfirm, onCancel, language, translations }) {

    const handleConfirm = (state) => {
        onConfirm(state);
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <section className='confirmationModal-wrapper'>
            <div className='confirmationModal-background'>
                <div className='confirmationModal-topSide'>
                    <div className='confirmationModal-title'>
                        <span>{translations.share_confirmationModel_areYouSure}</span>
                        <p>{translations.share_confirmationModel_actionIrreversible}</p>
                    </div>
                </div>

                <div className='confirmationModal-bottomSide'>
                    <button onClick={() => handleConfirm(true)}>{translations.share_confirmationModel_confirm}</button>
                    <button onClick={handleCancel}>{translations.share_confirmationModel_cancel}</button>
                </div>
            </div>
        </section>
    );
}

export default SharesModal;