import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import Step1 from './ForgotPassword/Step1';
import Step2 from './ForgotPassword/Step2';
import Step3 from './ForgotPassword/Step3';
import Step4 from './ForgotPassword/Step4';
import '../../style/auth/ForgotPassword.css';

function ForgotPassword() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [step, setStep] = useState(1);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [errorOTPCode, setErrorOTPCode] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isStep1Complete = email && !emailError;
    const isStep2Complete = email && password && repeatPassword && !emailError && password === repeatPassword;

    const isStep3Complete = OTPCode && !errorOTPCode;

    const sendEmailWithSecurityCode = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/verify-email`;
        const data = new URLSearchParams({
            email: email,
            language: language
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    setErrorMessage(translations.forgotPassword_somethingWentWrong);
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setErrorMessage('');
                    setStep(2);
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                setLoading(false);
            });
    };

    const validateSecurityCode = (e) => {
        e.preventDefault();
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/validate-token`;
        const data = new URLSearchParams({
            email: email,
            securityToken: OTPCode
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    setErrorMessage(translations.forgotPassword_somethingWentWrong);
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setErrorMessage('');
                    setStep(3);
                    return response.json();
                }
            })
            .then(data => {

            })
            .catch(error => {
                setLoading(false);
            });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/change-password`;

        const formData = new URLSearchParams({
            email: email,
            password: password,
            securityToken: OTPCode
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setErrorMessage(translations.forgotPassword_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setErrorMessage('');
                    setStep(4);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setErrorMessage(translations.forgotPassword_somethingWentWrong);
            });
    };

    return (
        <section className='register-content'>

            <div className='register-leftSide-container'>
                <div className='register-leftSide-logo'>
                    <a href={`/${language}`}><h1>Match the Look</h1></a>
                </div>
                <div className='register-leftSide-action'>
                    <span>{translations.forgotPassword_alreadyHaveAccount}</span>
                    <a href={`/${language}/auth/login`}>{translations.forgotPassword_logIn}</a>
                </div>
                {step === 1 && (
                    <>
                        <Step1
                            email={email}
                            setEmail={setEmail}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            translations={translations}
                        />

                        <div className='register-leftSide-container-form-button'>
                            {loading ? (
                                <button disabled>
                                    <img src='/loaders/icon-loading.png' alt='Loading' />
                                </button>
                            ) : (
                                <button onClick={sendEmailWithSecurityCode} disabled={!isStep1Complete}>{translations.forgotPassword_resetPassword}</button>
                            )}
                        </div>
                    </>
                )}

                {step === 2 && (
                    <>
                        <Step3
                            email={email}
                            OTPCode={OTPCode}
                            setOTPCode={setOTPCode}
                            error={errorOTPCode}
                            setError={setErrorOTPCode}
                            translations={translations}
                        />

                        <div className='register-leftSide-container-form-button'>
                            {loading ? (
                                <button disabled>
                                    <img src='/loaders/icon-loading.png' alt='Loading' />
                                </button>
                            ) : (
                                <button onClick={validateSecurityCode} disabled={!isStep3Complete}>{translations.forgotPassword_confirm}</button>
                            )}
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                        <Step2
                            email={email}
                            password={password}
                            setPassword={setPassword}
                            repeatPassword={repeatPassword}
                            setRepeatPassword={setRepeatPassword}
                            setErrorPassword={setErrorPassword}
                            errorPassword={errorPassword}
                            translations={translations}
                        />

                        {errorMessage !== '' && (
                            <div className='register-error-message'>
                                <span>{errorMessage}</span>
                            </div>
                        )}

                        <div className='register-leftSide-container-form-button'>
                            {loading ? (
                                <button disabled>
                                    <img src='/loaders/icon-loading.png' alt='Loading' />
                                </button>
                            ) : (
                                <button onClick={handleSubmit} disabled={!isStep2Complete}>{translations.forgotPassword_changePassword}</button>
                            )}
                        </div>
                    </>
                )}

                {step === 4 && (
                    <>
                        <Step4 
                        translations={translations}
                        />
                        <div className='register-leftSide-container-form-button'>
                            <a href={`/${language}/auth/login`}><button>{translations.forgotPassword_goToLogin}</button></a>
                        </div>
                    </>
                )}
            </div>
            <div className='register-rightSide-container'>
                <div className='register-rightSide-box-container'>
                    <div className='register-rightSide-box-profilePicture'>
                        <img src='/user2.jpg' alt='User' />
                    </div>
                    <div className='register-rightSide-box-quote'>
                        <p><b>{translations.forgotPassword_message1} </b></p><p>{translations.forgotPassword_message2}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPassword;
