import React, { useState, useRef, useEffect, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { FaHome, FaArrowLeft } from 'react-icons/fa';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/MenuTop.css';

function MenuTop() {
    const { language } = useContext(LanguageContext);
    const { isAuthenticated, getSessionInfo, logout } = useAuth();
    const userName = localStorage.getItem('name');
    const profilePicture = localStorage.getItem('picture');
    const { localStoredUsername } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isDropdownOpen]);

    return (
        <section className='menuTop-content'>
            <div className='menuTop-title'>
                <a href={`/${language}`}> <h1>{translations.common_mainTitleForCompany}</h1> </a>
            </div>
            <div className='menuTop-account'>
                <div className='menuTop-account-options' onClick={toggleDropdown}>
                    {!isAuthenticated ? (
                        <a href={`/${language}/auth/login`}>
                            <img src='/common/icon-default-picture.png' alt='Default Profile' className='menuTop-account-profilePicture' />
                            <h6>{translations.private_menuTop_myAccount}</h6>
                        </a>
                    ) : (
                        <>
                            {profilePicture !== "" ? (
                                <img src={`${MainContext.profilePictureURL}${profilePicture}`} alt={`${localStoredUsername} profile`} className='menuTop-account-profilePicture' />
                            ) : (
                                <img src='/common/icon-default-picture.png' alt='Default Profile' className='menuTop-account-profilePicture' />
                            )}
                            <h6>{userName}</h6>
                            <img src='/common/icon-down.png' alt='Down' className='menuTop-account-icon' />

                            {isDropdownOpen && (
                                <div className='menuTop-account-dropdown' ref={dropdownRef}>
                                    <ul className='menuTop-account-ul'>
                                        <li onClick={closeDropdown}><a href={`/${language}/dashboard/${localStoredUsername}/mural`}><FaArrowLeft className='menuTop-icon' />{translations.private_menuTop_returnCollections}</a></li>
                                        <li onClick={closeDropdown}><a href={`/${language}/dashboard/${localStoredUsername}`}><FaHome className='menuTop-icon' />{translations.private_menuTop_home}</a></li>
                                    </ul>
                                    <h4 className='menuTop-account-h4-mobile'>{translations.private_menuTop_collections}</h4>
                                    <ul className='menuTop-account-ul-mobile'>
                                        <li onClick={closeDropdown}><a href={`/${language}/dashboard/${localStoredUsername}/new-share`}>{translations.private_menuTop_newShare}</a></li>
                                    </ul>
                                    {/* 
                                    <h4 className='menuTop-account-h4-mobile'>{translations.private_rewardCollections}</h4>
                                    <ul className='menuTop-account-ul-mobile'>
                                        <li onClick={closeDropdown}><a href={`/${language}/dashboard/${localStoredUsername}/my-points`}>{translations.private_myPoints}</a></li>
                                    </ul>
                                    */}
                                    <h4 className='menuTop-account-h4'>{translations.private_menuTop_myProfile}</h4>
                                    <ul className='menuTop-account-ul'>
                                        <li onClick={closeDropdown}><a href={`/${language}/@${localStoredUsername}`}>{translations.private_menuTop_publicProfile}</a></li>
                                    </ul>
                                    <ul className='menuTop-account-ul'>
                                        <li onClick={() => logout(language)}>{translations.private_menuTop_logout}</li>
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}

export default MenuTop;
