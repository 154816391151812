import React from 'react';
import '../../../style/auth/Login/Step1.css';

function Login_Step1({ email, setEmail, setPassword, language, translations }) {

    return (
        <section className='login-step1-content'>
            <div className='login-step1-leftSide-container'>
                <div className='login-step1-leftSide-container-form'>
                    <div className='login-step1-leftSide-container-form-title'>
                        <h2>{translations.login_logIn}</h2>
                    </div>
                    <div className='login-step1-leftSide-container-form-separation-input'>
                        <div className='login-step1-leftSide-container-form-input'>
                            <label>{translations.login_step1_email}</label>
                            <input
                                type="text"
                                placeholder={translations.login_step1_email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value.replace(/\s+/g, ''))}
                            />
                        </div>
                    </div>
                    <div className='login-step1-leftSide-container-form-separation-input'>
                        <div className='login-step1-leftSide-container-form-input'>
                            <label>{translations.login_step1_password}</label>
                            <input
                                type='password'
                                placeholder={translations.login_step1_password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='login-forgotPassword'>
                        <a href={`/${language}/auth/forgot-password`}>{translations.login_step1_forgotPassword}</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login_Step1;