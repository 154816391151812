import React, { useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import { FaHome, FaArrowLeft, FaShareAlt, FaUser, FaGlobe } from 'react-icons/fa';
//import { MdStars } from 'react-icons/md';
import '../../style/private/MenuSide.css';

function MenuSide() {
    const { language } = useContext(LanguageContext);
    const { getSessionInfo } = useAuth();
    const { localStoredUsername } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <aside className='menuSide-content'>
            <div className='menu-section'>
                <ul>
                    <li><a href={`/${language}/dashboard/${localStoredUsername}/mural`}><FaArrowLeft className='menu-icon' />{translations.private_menuTop_returnCollections}</a></li>
                    <li><a href={`/${language}/dashboard/${localStoredUsername}`}><FaHome className='menu-icon' />{translations.private_menuTop_home}</a></li>
                </ul>
            </div>

            <div className='menu-section'>
                <h2>{translations.private_menuTop_collections}</h2>
                <ul>
                    <li><a href={`/${language}/dashboard/${localStoredUsername}/new-share`}><FaShareAlt className='menu-icon' />{translations.private_menuTop_newShare}</a></li>
                </ul>
            </div>

            {/* 
            <div className='menu-section'>
                <h2>{translations.private_rewardCollections}</h2>
                <ul>
                    <li><a href={`/${language}/dashboard/${localStoredUsername}/my-points`}><MdStars className='menu-icon' />{translations.private_myPoints}</a></li>
                </ul>
            </div>
            */}

            <div className='menu-section'>
                <h2>{translations.private_menuTop_myProfile}</h2>
                <ul>
                    <li><a href={`/${language}/dashboard/${localStoredUsername}/my-account`}><FaUser className='menu-icon' />{translations.private_menuTop_myAccount}</a></li>
                    <li>
                        <a href={`/${language}/@${localStoredUsername}`}><FaGlobe className='menu-icon' />{translations.private_menuTop_publicProfile}</a>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default MenuSide;
