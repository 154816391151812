import React, { useState, useEffect } from 'react';
import '../style/components/muralFilters-public.css';

function MuralFiltersPublic({ setFiltersOpen, targetGender, setTargetGender, targetStyle, setTargetStyle, setValuesChanged, translations }) {
  const [filterGender, setFilterGender] = useState(targetGender);
  const [filterStyle, setFilterStyle] = useState(targetStyle);

  const isDisabled = !(filterGender && filterStyle);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const handleGenderChange = (event) => {
    setFilterGender(event.target.value);
  };

  const handleStyleChange = (event) => {
    setFilterStyle(event.target.value);
  };

  const handleSubmit = () => {
    setTargetGender(filterGender);
    setTargetStyle(filterStyle);
    setValuesChanged(true);
    setFiltersOpen(false);
  }

  return (
    <section className='muralFilters-public-wrapper'>
      <div className='muralFilters-public-background-wrapper'>
        <div className='muralFilters-public-title'>
          <div className='muralFilters-public-title-leftSide'>
            <h3>{translations.muralSharesFilter_title}</h3>
          </div>
          <div className='muralFilters-public-title-rightSide'>
            <img src='/common/icon-close.png' alt='Close' onClick={() => setFiltersOpen(false)} />
          </div>
        </div>
        <div className='muralFilters-public-filters-wrapper'>
          <div className='muralFilters-public-filters-leftSide'>
            <h4>{translations.muralSharesFilter_gender}</h4>
            <div className='muralFilters-public-filters-gender'>
              <label> <input type="radio" name="gender" value="all" checked={filterGender === 'all'} onChange={handleGenderChange} /> {translations.muralSharesFilter_genderAll} </label>
              <label> <input type="radio" name="gender" value="man" checked={filterGender === 'man'} onChange={handleGenderChange} /> {translations.muralSharesFilter_genderMan} </label>
              <label> <input type="radio" name="gender" value="woman" checked={filterGender === 'woman'} onChange={handleGenderChange} /> {translations.muralSharesFilter_genderWoman} </label>
            </div>
          </div>
          <div className='muralFilters-public-filters-rightSide'>
            <h4>{translations.muralSharesFilter_style}</h4>
            <div className='muralFilters-public-filters-style'>
              <label> <input type="radio" name="style" value="all" checked={filterStyle === 'all'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleAll} </label>
              <label> <input type="radio" name="style" value="casual" checked={filterStyle === 'casual'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleCasual} </label>
              <label> <input type="radio" name="style" value="formal" checked={filterStyle === 'formal'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleFormal} </label>
              <label> <input type="radio" name="style" value="sporty" checked={filterStyle === 'sporty'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleSporty} </label>
              <label> <input type="radio" name="style" value="elegant" checked={filterStyle === 'elegant'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleElegant} </label>
              <label> <input type="radio" name="style" value="vintage" checked={filterStyle === 'vintage'} onChange={handleStyleChange} /> {translations.muralSharesFilter_styleVintage} </label>
            </div>
          </div>
        </div>
        <div className="muralFilters-public-submit">
          <button type="submit" disabled={isDisabled} onClick={handleSubmit}>{translations.muralSharesFilter_buttonUpdate}</button>
        </div>
      </div >
    </section >
  );
}

export default MuralFiltersPublic;
