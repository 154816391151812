import React, { useState, useEffect } from 'react';
import '../../../style/auth/Register/Step3.css';

function Register_Step1({ email, OTPCode, setOTPCode, error, setError, translations }) {

    const [seconds, setSeconds] = useState(120);
    const [retryCount, setRetryCount] = useState(2);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (seconds > 0) {
            const timerId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timerId);
        } else {
            setRetryCount(retryCount + 1);
            sendEmail();
        }
    }, [seconds]);
    /* eslint-disable react-hooks/exhaustive-deps */

    const formatTime = () => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return minutes > 0
            ? `${minutes} minute${minutes > 1 ? 's' : ''} and ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`
            : `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    };

    function sendEmail() {
        setSeconds(retryCount * 120);
    }

    return (
        <section className='register-step1-content'>
            <div className='register-step1-leftSide-container'>
                <div className='register-step1-leftSide-container-form'>
                    <div className='register-step1-leftSide-container-form-title'>
                        <h2>{translations.forgotPassword_step1_forgotPassword}</h2>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step3-leftSide-message-container'>
                            <span className='register-step3-leftSide-message-small'>{translations.referralCode_step3_message1}</span>
                            <span className='register-step3-leftSide-message-small'>{translations.referralCode_step3_message2} {email}</span>
                            <span className='register-step3-leftSide-message-big'>{translations.referralCode_step3_message3}</span>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className={error ? ('register-step2-leftSide-container-form-input-error') : ('register-step1-leftSide-container-form-input')}>
                            <label>{translations.referralCode_step3_verificationCode}</label>
                            <input
                                type="text"
                                placeholder={translations.referralCode_step3_verificationCode}
                                value={OTPCode}
                                onChange={(event) => {
                                    let numericValue = event.target.value.replace(/\D/g, '');
                                    if (numericValue.length > 6) {
                                        numericValue = numericValue.slice(0, 6);
                                    }
                                    setOTPCode(numericValue);
                                }}
                            />
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step3-leftSide-email-resend'>
                            <div className='register-step3-leftSide-email-resend'>
                                <span>{translations.referralCode_step3_resendIn} {formatTime()}</span>
                            </div>
                        </div>
                    </div>
                    {error && (
                        <div className='register-step1-leftSide-container-form-separation-input'>
                            <div className='register-step3-error-message'>
                                <span>{translations.referralCode_step3_invalidCode}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>

    );
}

export default Register_Step1;