import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section5_PartnerWithUs.css';

function Section5_PartnerWithUs() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='section5_partnerWithUs-content'>
            <div className='section5_container'>
                <h4>{translations.landingPage_section5_whyPartnerWithUs}</h4>
                <div className='section5_partner-list'>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>🌐</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_expandReach}</h5>
                            <p>{translations.landingPage_section5_expandReach_cause}</p>
                        </div>
                    </div>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>📊</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_advancedAnalytics}</h5>
                            <p>{translations.landingPage_section5_advancedAnalytics_cause}</p>
                        </div>
                    </div>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>📈</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_trendInsights}</h5>
                            <p>{translations.landingPage_section5_trendInsights_cause}</p>
                        </div>
                    </div>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>🔗</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_effortlessIntegration}</h5>
                            <p>{translations.landingPage_section5_effortlessIntegration_cause}</p>
                        </div>
                    </div>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>🚀</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_exclusiveMarketing}</h5>
                            <p>{translations.landingPage_section5_exclusiveMarketing_cause}</p>
                        </div>
                    </div>
                    <div className='section5_partner-item'>
                        <div className='section5_icon'>🌟</div>
                        <div className='section5_partner-content'>
                            <h5>{translations.landingPage_section5_userEngagment}</h5>
                            <p>{translations.landingPage_section5_userEngagment_cause}</p>
                        </div>
                    </div>
                </div>
                <div className='section5_cta-section'>
                    <a href={`/${language}/partnerships`} className='section5_cta-button-partner'>{translations.landingPage_section5_cta}</a>
                </div>
            </div>
        </section>
    );
}

export default Section5_PartnerWithUs;
