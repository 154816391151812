import React from 'react';
import Profile from './MyAccount/Profile';
import PersonalInformation from './MyAccount/PersonalInformation';
import ChangePassword from './MyAccount/ChangePassword';
import SocialMedia from './MyAccount/SocialMedia';
import '../../style/private/MyAccount.css';

function MyAccount() {
    return (
        <section className='myAccount-content'>
            <div className='myAccount-section'>
                <Profile />
            </div>
            <div className='myAccount-section'>
                <PersonalInformation />
            </div>
            <div className='myAccount-section'>
                <ChangePassword />
            </div>
            <div className='myAccount-section'>
                <SocialMedia />
            </div>
        </section >
    );
}

export default MyAccount;
