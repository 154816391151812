import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section7_FAQ.css';

function Section7_FAQ() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const faqData = [
        {
            question: translations.landingPage_section7_question1,
            answer: translations.landingPage_section7_question1_answer
        },
        {
            question: translations.landingPage_section7_question2,
            answer: translations.landingPage_section7_question2_answer
        },
        {
            question: translations.landingPage_section7_question3,
            answer: translations.landingPage_section7_question3_answer
        },
        {
            question: translations.landingPage_section7_question4,
            answer: translations.landingPage_section7_question4_answer
        },
        {
            question: translations.landingPage_section7_question5,
            answer: translations.landingPage_section7_question5_answer
        },
        {
            question: translations.landingPage_section7_question6,
            answer: translations.landingPage_section7_question6_answer
        }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className='section7_faq-content'>
            <div className='section7_faq-content-wrapper'>
                <h2 className='section7_faq-title'>{translations.landingPage_section7_faq}</h2>

                {faqData.map((faq, index) => (
                    <div className='section7_faq-accordion' key={index}>
                        <button
                            className='section7_faq-question'
                            onClick={() => toggleAccordion(index)}
                        >
                            {faq.question}
                        </button>
                        <div className={`section7_faq-answer ${openIndex === index ? 'active' : ''}`}>
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Section7_FAQ;
