import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import MainContext from '../../../context/MainContext'
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section6_PremiumFeatures.css';

function Section6_PremiumFeatures() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [subscribed, setSubscribed] = useState(false);

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailPattern.test(emailValue));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const baseURL = `${MainContext.apiURL}/api/v1/public/contacts/send/message`;

        const formData = new URLSearchParams({
            name: 'Not applicable',
            email: email,
            message: 'Premium Features user waiting list'
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setSubscribed(true);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error activating account:', error);
            });
    };

    return (
        <section className='section6_premiumFeatures-content'>
            <div className='section6_premiumFeatures-container'>
                <h2 className='section6_premiumFeatures-headline'>{translations.landingPage_section6_unlockPremiumFeaturesSoon}</h2>
                <div className='section6_premiumFeatures-details'>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>🔍</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_advancedInsights}</p>
                        </div>
                    </div>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>🔗</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_customURL}</p>
                        </div>
                    </div>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>🎯</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_pointingSystem}</p>
                        </div>
                    </div>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>🏅</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_rewardsAndExclusiveBenefits}</p>
                        </div>
                    </div>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>✨</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_additionalTools}</p>
                        </div>
                    </div>
                    <div className='section6_premiumFeatures-detail'>
                        <div className='section6_premiumFeatures-icon'>🎁</div>
                        <div className='section6_premiumFeatures-text'>
                            <p>{translations.landingPage_section6_andMuchMore}</p>
                        </div>
                    </div>
                </div>
                <div className='section6_premiumFeatures-cta'>
                    <div className='section6_premiumFeatures-cta-input-wrapper'>
                        <input type='email' placeholder='Enter your email' value={email} onChange={handleEmailChange} className='section6_premiumFeatures-emailInput' />
                        {subscribed ? (
                            <span>{translations.landingPage_section6_subscribed}</span>
                        ) : (
                            <button className='section6_premiumFeatures-ctaButton' onClick={sendEmail} disabled={!isEmailValid}>{translations.landingPage_section6_joinWaitingList}</button>
                        )}
                    </div>
                    <div className='section6_premiumFeatures-cta-message-wrapper'>
                        <p className='section6_premiumFeatures-rewardMessage'>{translations.landingPage_section6_joinWaitingList_price}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section6_PremiumFeatures;
