import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/components/shareButtons.css';

const ShareButtons = ({ url, title }) => {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const encodedURL = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedEmailBody = encodeURIComponent(`${title} - ${url}`);

  return (
    <div className='shareButtons-wrapper'>

      <div className='shareButtons-title'>
        <p>{translations.share_shareThisLook}</p>
      </div>
      <div className='shareButtons-buttons'>
        {/* Facebook */}
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src='/socials/icon-facebook.png' alt='Facebook' />
        </a>

        {/* Twitter */}
        <a
          href={`https://twitter.com/intent/tweet?url=${encodedURL}&text=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src='/socials/icon-twitter.png' alt='Twitter' />
        </a>

        {/* Instagram (suggest manual sharing since direct sharing is not supported) */}
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => alert('Please copy the link and share it on Instagram!')}
        >
          <img src='/socials/icon-instagram.png' alt='Instagram' />
        </a>

        {/* WhatsApp */}
        <a
          href={`https://api.whatsapp.com/send?text=${encodedTitle} ${encodedURL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src='/socials/icon-whatsapp.png' alt='Whatsapp' />
        </a>

        {/* Email */}
        <a
          href={`mailto:?subject=${encodedTitle}&body=${encodedEmailBody}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src='/socials/icon-email.png' alt='Email' />
        </a>
      </div>
    </div>
  );
};

export default ShareButtons;
