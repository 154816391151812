import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/public/Partnerships.css';

function Partnerships() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [message, setMessage] = useState('');

    const purposeOfInquiryOptions = [
        { value: 'partnership', label: translations.partnerships_partnershipsOpportunity },
        { value: 'collaboration', label: translations.partnerships_collaboration },
        { value: 'sponsorship', label: translations.partnerships_sponsorship },
        { value: 'event', label: translations.partnerships_eventParticipation },
        { value: 'customization', label: translations.partnerships_customRequest },
        { value: 'media', label: translations.partnerships_mediaOrPress },
        { value: 'other', label: translations.partnerships_other }
    ];

    const features = [
        {
            title: translations.partnerships_features1_title,
            description: translations.partnerships_features1_description,
            icon: '🌐' // Globe icon
        },
        {
            title: translations.partnerships_features2_title,
            description: translations.partnerships_features2_description,
            icon: '📊' // Bar chart icon
        },
        {
            title: translations.partnerships_features3_title,
            description: translations.partnerships_features3_description,
            icon: '📈' // Line chart icon
        },
        {
            title: translations.partnerships_features4_title,
            description: translations.partnerships_features4_description,
            icon: '🧩' // Puzzle piece icon
        },
        {
            title: translations.partnerships_features5_title,
            description: translations.partnerships_features5_description,
            icon: '📣' // Megaphone icon
        },
        {
            title: translations.partnerships_features6_title,
            description: translations.partnerships_features6_description,
            icon: '👥' // Users icon
        },
        {
            title: translations.partnerships_features7_title,
            description: translations.partnerships_features7_description,
            icon: '💾' // Floppy disk icon
        },
        {
            title: translations.partnerships_features8_title,
            description: translations.partnerships_features8_description,
            icon: '🚀' // Rocket icon
        }
    ];

    const [countryOptions, setCountryOptions] = useState([]);
    const [country, setCountry] = useState('');
    const [purposeOfInquiry, setPurposeOfInquiry] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCountryList();
        const element = document.querySelector('.partnerships-leftSide');
        if (element) {
            element.scrollTop = 0;
        }

    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const getCountryList = () => {
        const data = new URLSearchParams({
            language: language
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data);
                setCountryOptions(data.countries);
            })
            .catch(error => {

            });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        if (companyName === '' || name === '' || email === '' || website === '' || country === '' || purposeOfInquiry === '' || message === '') {
            setLoading(false);
            return;
        }

        const baseURL = `${MainContext.apiURL}/api/v1/public/contacts/send/partnership`;

        const formData = new URLSearchParams({
            companyName: companyName,
            name: name,
            email: email,
            website: website,
            country: country,
            objective: purposeOfInquiry,
            message: message
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoading(false);
                    setSuccess(translations.partnerships_emailSent);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
            });
    };

    return (
        <section className='partnerships-content'>
            <div className='partnerships-leftSide'>
                <div className='register-step1-leftSide-container'>
                    <div className='register-step1-leftSide-container-form'>
                        <div className='register-step1-leftSide-container-form-title'>
                            <p>{translations.partnerships_subTitle}</p>
                            <h1>{translations.partnerships_title}</h1>
                        </div>

                        <div className='register-step1-leftSide-container-form-separation-input'>
                            <div className='register-step1-leftSide-container-form-input'>
                                <label>{translations.partnerships_companyName}</label>
                                <input type="text" placeholder={translations.partnerships_companyName} value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
                            </div>
                            <div className='register-step1-leftSide-container-form-input'>
                                <label>{translations.partnerships_yourName}</label>
                                <input type="text" placeholder={translations.partnerships_yourName} value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.partnerships_email}</label>
                            <input type="text" placeholder={translations.partnerships_email} value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.partnerships_companyWebsite}</label>
                            <input type="text" placeholder={translations.partnerships_companyWebsite} value={website} onChange={(e) => setWebsite(e.target.value)}/>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.partnerships_country}</label>
                            <select
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className='register-step1-leftSide-container-form-select'
                                required >
                                <option value="" disabled>{translations.partnerships_country_select}</option>
                                {countryOptions.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.country}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.partnerships_purpouse}</label>
                            <select
                                id="country"
                                value={purposeOfInquiry}
                                onChange={(e) => setPurposeOfInquiry(e.target.value)}
                                className='register-step1-leftSide-container-form-select'
                                required >
                                <option value="" disabled>{translations.partnerships_purpouse_select}</option>
                                {purposeOfInquiryOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step1-leftSide-container-form-input'>
                            <label>{translations.partnerships_message}</label>
                            <textarea type="text" placeholder={translations.partnerships_yourMessage} value={message} onChange={(e) => setMessage(e.target.value)}/>
                        </div>
                    </div>
                    <div className='register-leftSide-container-form-button'>
                        {loading ? (
                            <button disabled>
                                <img src='/loaders/icon-loading.png' alt='Loading' />
                            </button>
                        ) : (
                            <>
                                {success !== '' ? (
                                    <span className='section8_contacts-success'>{success}</span>
                                ) : (
                                    <>
                                        <button onClick={sendEmail}>{translations.partnerships_submit}</button>
                                    </>
                                )}

                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='partnerships-rightSide'>
                <div className='partnerships-rightSide-container'>
                    <div className='partnerships-rightSide-wrapper'>
                        {features.map((feature, index) => (
                            <div key={index} className='partnerships-rightSide-feature'>
                                <div className='partnerships-rightSide-feature-icon'>
                                    <span>{feature.icon}</span>
                                </div>
                                <div className='partnerships-rightSide-feature-content'>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partnerships;
