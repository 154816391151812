import React from 'react';
import '../../../style/auth/Register/Step1.css';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

function Register_Step1({ email, setEmail, emailError, setEmailError, translations }) {

    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
            setEmailError('');
        } else {
            setEmailError(translations.forgotPassword_step1_invalidEmail);
        }
    };

    const debouncedValidateEmail = debounce(validateEmail, 300);

    function handleEmailChange(event) {
        const value = event.target.value;
        setEmail(value);
        debouncedValidateEmail(value);
    }

    return (
        <section className='register-step1-content'>
            <div className='register-step1-leftSide-container'>
                <div className='register-step1-leftSide-container-form'>
                    <div className='register-step1-leftSide-container-form-title'>
                        <h2>{translations.forgotPassword_step1_forgotPassword}</h2>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className='register-step3-leftSide-message-container'>
                            <span className='register-step3-leftSide-message-big'>{translations.forgotPassword_step1_message1}</span>
                        </div>
                    </div>
                    <div className='register-step1-leftSide-container-form-separation-input'>
                        <div className={emailError ? ('register-step2-leftSide-container-form-input-error') : ('register-step1-leftSide-container-form-input')}>
                            <label>{translations.forgotPassword_step1_email}</label>
                            <input
                                type="text"
                                placeholder={translations.forgotPassword_step1_email}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register_Step1;
