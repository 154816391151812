import React, { useState, useContext } from 'react';
import { useAuth } from '../../../context/AuthContext';
import MainContext from '../../../context/MainContext'
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/private/MyAccount/ChangePassword.css';

function MyAccount() {
    const { getSessionInfo } = useAuth();
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [isFocusedPassword, setIsFocusedPassword] = useState(false);
    const [isFocusedRepeatPassword, setIsFocusedRepeatPassword] = useState(false);

    const [passwordCriteria, setNewPasswordCriteria] = useState({
        length: false,
        uppercase: false,
        number: false,
        symbol: false
    });

    const [repeatPasswordCriteria, setRepeatPasswordCriteria] = useState({
        length: false,
        uppercase: false,
        number: false,
        symbol: false
    });

    const validatePassword = (pwd) => {
        setNewPasswordCriteria({
            length: pwd.length >= 8,
            uppercase: /[A-Z]/.test(pwd),
            number: /[0-9]/.test(pwd),
            symbol: /[!@#$%^&*(),.?":{}|<>]/.test(pwd)
        });
    };

    const validateRepeatPassword = (pwd) => {
        setRepeatPasswordCriteria({
            length: pwd.length >= 8,
            uppercase: /[A-Z]/.test(pwd),
            number: /[0-9]/.test(pwd),
            symbol: /[!@#$%^&*(),.?":{}|<>]/.test(pwd)
        });
    };

    const handleNewPasswordChange = (e) => {
        const pwd = e.target.value;
        setNewPassword(pwd);
        validatePassword(pwd);
    };

    const handleRepeatPasswordChange = (e) => {
        const pwd = e.target.value;
        setRepeatPassword(pwd);
        validateRepeatPassword(pwd);
    };

    const isButtonDisabled = !(oldPassword &&
        newPassword === repeatPassword &&
        passwordCriteria.length &&
        passwordCriteria.number &&
        passwordCriteria.symbol &&
        passwordCriteria.uppercase &&
        repeatPasswordCriteria.length &&
        repeatPasswordCriteria.number &&
        repeatPasswordCriteria.symbol &&
        repeatPasswordCriteria.uppercase
    );

    const updatePassword = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/password`;

        const formData = new URLSearchParams({
            oldPassword: oldPassword,
            newPassword: newPassword,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setError(translations.private_account_changePassword_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setError('');
                    setLoading(false);
                    setSuccess(translations.private_account_changePassword_informationUpdatedSuccess);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    };

    return (
        <section className='myAccount-changePassword-content'>

            <div className='myAccount-border-descriptor'>
                <h3>{translations.private_account_changePassword_title}</h3>
                <p>{translations.private_account_changePassword_subtitle}</p>
            </div>

            <div className='myAccount-border-content'>

                <form onSubmit={updatePassword}>
                    <div className='myAccount-changePassword-old-password'>
                        <label>{translations.private_account_changePassword_oldPassword}</label>
                        <input
                            type="password"
                            className="myAccount-changePassword-input"
                            placeholder={translations.private_account_changePassword_oldPassword_placeholder}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div className='myAccount-changePassword-password-divider'>
                        <div className='myAccount-changePassword-new-password'>
                            <label>{translations.private_account_changePassword_newPassword}</label>
                            <input
                                type="password"
                                className="myAccount-changePassword-input"
                                placeholder={translations.private_account_changePassword_newPassword_placeholder}
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                onFocus={() => setIsFocusedPassword(true)}
                                onBlur={() => setIsFocusedPassword(false)}
                                required
                            />
                            {isFocusedPassword && (
                                <div className='myAccount-changePassword-passwordValidation'>
                                    <ul>
                                        <li className={passwordCriteria.length ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_1}
                                        </li>
                                        <li className={passwordCriteria.uppercase ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_2}
                                        </li>
                                        <li className={passwordCriteria.number ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_3}
                                        </li>
                                        <li className={passwordCriteria.symbol ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_4}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className='myAccount-changePassword-repeat-new-password'>
                            <label>{translations.private_account_changePassword_repeat_newPassword}</label>
                            <input
                                type="password"
                                className="myAccount-changePassword-input"
                                placeholder={translations.private_account_changePassword_repeat_newPassword_placeholder}
                                value={repeatPassword}
                                onChange={handleRepeatPasswordChange}
                                onFocus={() => setIsFocusedRepeatPassword(true)}
                                onBlur={() => setIsFocusedRepeatPassword(false)}
                                required
                            />
                            {isFocusedRepeatPassword && (
                                <div className='myAccount-changePassword-passwordValidation'>
                                    <ul>
                                        <li className={passwordCriteria.length ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_1}
                                        </li>
                                        <li className={passwordCriteria.uppercase ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_2}
                                        </li>
                                        <li className={passwordCriteria.number ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_3}
                                        </li>
                                        <li className={passwordCriteria.symbol ? 'valid' : 'invalid'}>
                                            {translations.private_account_changePassword_requirments_4}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    {error !== '' && (
                        <div className='myAccount-changePassword-error'>
                            <span>{error}</span>
                        </div>
                    )}

                    {success !== '' && (
                        <div className='myAccount-changePassword-success'>
                            <span>{success}</span>
                        </div>
                    )}

                    <button type='submit' className='myAccount-changePassword-button-submit' disabled={isButtonDisabled}>
                        {loading ? (
                            <img src='/loaders/icon-loading.png' alt='' />
                        ) : (
                            <>
                                {translations.private_account_changePassword_changePassowrd}
                            </>
                        )}
                    </button>
                </form>

            </div>
        </section >
    );
}

export default MyAccount;
