import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json';
import ptTranslations from '../../translations/pt.json';
import LOGIN_STEP1 from './Login/Step1';
import '../../style/auth/Login.css';

function Login() {
    const { username, share } = useParams();
    const { login } = useAuth();
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isStep1Complete = email && password;

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const loginUsers = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/login`;

        const formData = new URLSearchParams({
            email: email,
            password: password,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setErrorMessage(translations.authLogin_errorLoggingIn);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                login(data.name, data.email, data.username, data.session, data.picture, data.gender);

                setLoading(false);
                setErrorMessage('');
                if (username !== undefined && share !== undefined) {
                    navigate(`/${language}/${username}/share/${share}`);
                } else {
                    navigate(`/${language}/dashboard/${data.username}`);
                }
            })
            .catch(error => {
                console.error('Error logging in:', error);
                setLoading(false);
                setErrorMessage(translations.login_somethingWentWrong);
            });
    };

    return (
        <section className='login-content'>
            <div className='login-leftSide-container'>
                <div className='login-leftSide-logo'>
                    <a href={`/${language}`}><h1>Match the Look</h1></a>
                </div>
                <div className='login-leftSide-action'>
                    <span>{translations.login_stillNotMember}</span>
                    <a href={`/${language}/auth/register`}>{translations.login_joinUs}</a>
                </div>

                <LOGIN_STEP1
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    language={language}
                    translations={translations}
                />

                {errorMessage !== '' && (
                    <div className='register-error-message'>
                        <span>{errorMessage}</span>
                    </div>
                )}

                <div className='register-leftSide-container-form-button'>
                    {loading ? (
                        <button disabled>
                            <img src='/loaders/icon-loading.png' alt='Loading' />
                        </button>
                    ) : (
                        <button onClick={loginUsers} disabled={!isStep1Complete}>{translations.login_logIn}</button>
                    )}
                </div>
            </div>

            <div className='login-rightSide-container'>
                <div className='login-rightSide-box-container'>
                    <div className='login-rightSide-box-profilePicture'>
                        <img src='/user1.jpg' alt='User' />
                    </div>
                    <div className='login-rightSide-box-quote'>
                        <p><b>{translations.login_message1}</b></p>
                        <p>{translations.login_message2}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
