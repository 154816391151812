import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import { SharesProvider } from './context/SharesContext';

import PUBLIC_LANDING_PAGE from './hookers/public/Hooker_LandingPage'
import PUBLIC_PARTNERSHIPS from './hookers/public/Hooker_Partnerships'
import PUBLIC_MURALSHARES from './hookers/public/Hooker_MuralShares'
import PUBLIC_SHARE from './hookers/public/Hooker_Share'
import PUBLIC_MY_PROFILE from './hookers/public/Hooker_MyProfile'

import AUTH_LOGIN from './hookers/auth/Hooker_Login'
import AUTH_REGISTER from './hookers/auth/Hooker_Register'
import AUTH_REFERRAL_REGISTER from './hookers/auth/Hooker_ReferralRegister'
import AUTH_FORGOT_PASSWORD from './hookers/auth/Hooker_ForgotPassword'

import PRIVATE_DASHBOARD from './hookers/private/Hooker_Dashboard'
import PRIVATE_MURALSHARES from './hookers/private/Hooker_MuralShares'
import PRIVATE_MY_ACCOUNT from './hookers/private/Hooker_MyAccount'
import PRIVATE_NEW_SHARE from './hookers/private/Hooker_NewShare'
import PRIVATE_EDIT_SHARE from './hookers/private/Hooker_EditShare'

import PRIVATE_USER_POINTS from './hookers/private/Hooker_UserPoints'

import TERMS_CONDITIONS from './policies/TermsConditions';
import COOKIES_POLICY from './policies/CookiesPolicy';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <LanguageProvider>
        <SharesProvider>
          <Routes>            
            {/* -------------- PUBLIC ROUTES -------------- */}

            <Route path="/" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/pt" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/en" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/pt/partnerships" element={<PUBLIC_PARTNERSHIPS />} />
            <Route path="/en/partnerships" element={<PUBLIC_PARTNERSHIPS />} />
            <Route path="/pt/mural" element={<PUBLIC_MURALSHARES />} />
            <Route path="/en/mural" element={<PUBLIC_MURALSHARES />} />
            <Route path="/pt/:username/share/:share" element={<PUBLIC_SHARE />} />
            <Route path="/en/:username/share/:share" element={<PUBLIC_SHARE />} />
            <Route path="/:username" element={<PUBLIC_MY_PROFILE />} />
            <Route path="/pt/:username" element={<PUBLIC_MY_PROFILE />} />
            <Route path="/en/:username" element={<PUBLIC_MY_PROFILE />} />

            {/* -------------- AUTHENTICATION ROUTES -------------- */}
            <Route path="/login" element={<Navigate to="/en/auth/login" replace />} />
            <Route path="/auth/login" element={<Navigate to="/en/auth/login" replace />} />
            <Route path="/pt/auth/login" element={<AUTH_LOGIN />} />
            <Route path="/en/auth/login" element={<AUTH_LOGIN />} />
            <Route path="/pt/auth/login/:username/share/:share" element={<AUTH_LOGIN />} />
            <Route path="/en/auth/login/:username/share/:share" element={<AUTH_LOGIN />} />
            <Route path="/register" element={<Navigate to="/en/auth/register" replace />} />
            <Route path="/auth/register" element={<Navigate to="/en/auth/register" replace />} />
            <Route path="/pt/auth/register" element={<AUTH_REGISTER />} />
            <Route path="/en/auth/register" element={<AUTH_REGISTER />} />
            <Route path="/pt/:username/referral/:referralcode" element={<AUTH_REFERRAL_REGISTER />} />
            <Route path="/en/:username/referral/:referralcode" element={<AUTH_REFERRAL_REGISTER />} />
            <Route path="/forgot-password" element={<Navigate to="/en/auth/forgot-password" replace />} />
            <Route path="/auth/forgot-password" element={<Navigate to="/en/auth/forgot-password" replace />} />
            <Route path="/pt/auth/forgot-password" element={<AUTH_FORGOT_PASSWORD />} />
            <Route path="/en/auth/forgot-password" element={<AUTH_FORGOT_PASSWORD />} />

            {/* -------------- PRIVATE ROUTES -------------- */}
            <Route path="/pt/dashboard/:username" element={isAuthenticated ? (<PRIVATE_DASHBOARD />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username" element={isAuthenticated ? (<PRIVATE_DASHBOARD />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/pt/dashboard/:username/mural" element={isAuthenticated ? (<PRIVATE_MURALSHARES />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username/mural" element={isAuthenticated ? (<PRIVATE_MURALSHARES />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/pt/dashboard/:username/my-account" element={isAuthenticated ? (<PRIVATE_MY_ACCOUNT />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username/my-account" element={isAuthenticated ? (<PRIVATE_MY_ACCOUNT />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/pt/dashboard/:username/new-share" element={isAuthenticated ? (<PRIVATE_NEW_SHARE />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username/new-share" element={isAuthenticated ? (<PRIVATE_NEW_SHARE />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/pt/dashboard/:username/edit-share/:share" element={isAuthenticated ? (<PRIVATE_EDIT_SHARE />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username/edit-share/:share" element={isAuthenticated ? (<PRIVATE_EDIT_SHARE />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/en/dashboard/:username/my-points" element={isAuthenticated ? (<PRIVATE_USER_POINTS />) : (<Navigate to="/auth/login" replace />)} />
            <Route path="/pt/dashboard/:username/my-points" element={isAuthenticated ? (<PRIVATE_USER_POINTS />) : (<Navigate to="/auth/login" replace />)} />

            {/* -------------- POLICIES ROUTES -------------- */}
            <Route path="/terms" element={<TERMS_CONDITIONS />} />
            <Route path="/cookies" element={<COOKIES_POLICY />} />

            {/* -------------- DEFAULT ROUTES -------------- */}
            
          </Routes>
        </SharesProvider>
      </LanguageProvider>
    </Router >
  );
}

export default App;