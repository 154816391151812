import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Cropper from 'react-easy-crop';
import '../../../style/private/NewShare/Image.css';

const NewShareImage = forwardRef(({ onImageSelect, translations }, ref) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageChoosed, setImageChoosed] = useState(false);

  const removeFileChange = () => {
    setCrop({ x: 0, y: 0 });
    setImage(null);
    setZoom(1);
    setCroppedAreaPixels(null);
    setImageChoosed(false);
    onImageSelect(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setImage(URL.createObjectURL(selectedFile));
      setImageChoosed(true);
      onImageSelect(selectedFile);
    } else {
      setImage(null);
      setImageChoosed(false);
      onImageSelect(null);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  async function getCroppedImg(imageSrc, pixelCrop, flip = { horizontal: false, vertical: false }) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = image;

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    });
  }

  // Function to be exposed to parent
  const submitCroppedImage = async () => {
    if (!image || !croppedAreaPixels) {
      throw new Error('Image or cropped area is not defined');
    }
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    const response = await fetch(croppedImage);
    return response;
  };

  useImperativeHandle(ref, () => ({
    submitCroppedImage,
  }));

  return (
    <section className='newShare-image-content'>
      <div className='newShare-uploadImage-container'>
        {!imageChoosed ? (
          <>
            <label className='newShare-uploadImage-wrapper' htmlFor='fileInput'>
              <img src='/common/icon-upload-picture.png' alt='Upload' />
              <span>{translations.private_newShare_uploadNewImage}</span>
            </label>
            <p align="center">
              <input
                type="file"
                id="fileInput"
                accept='image/*'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </p>
          </>
        ) : (
          <div className='newShare-img-container'>
            <div className='newShare-img-crop'>
              {image && (
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={0.75}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              )}
            </div>
            <div className='newShare-img-close'>
              <img src='/common/icon-close.png' alt='Remove' onClick={removeFileChange} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
});

export default NewShareImage;
