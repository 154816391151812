import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import ProfileLoading from '../../components/userprofile-loading'
import ProfileComponent from '../../components/user-profile'
import '../../style/public/MyProfile.css';

function MyProfile() {
    const { username } = useParams();
    const { language } = useContext(LanguageContext);

    const [results, setResults] = useState({
        user: {
            username: '',
            name: '',
            picture: ''
        },
        socialMedia: {
            facebook: null,
            instagram: null,
            linkedin: null,
            tiktok: null,
            youtube: null,
            other: null
        },
        shares: [],
        control: {
            isEnd: false,
            index: '',
        }
    });
    const [shareCount, setShareCount] = useState(0);
    const [loading, setLoading] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoading(true);

        let processedUsername = username.toLocaleLowerCase();
        if (username.startsWith('@')) {
            processedUsername = username.substring(1);
        } else {
            window.location.href = "/" + language + "/@" + username;
        }

        loadUsersFirstShares(processedUsername);
        /* eslint-disable react-hooks/exhaustive-deps */

    }, [username]);

    async function loadUsersFirstShares(processedUsername) {
        const baseURL = `${MainContext.apiURL}/api/v1/public/shares/profile/shares/first`;

        const data = new URLSearchParams({
            username: processedUsername,
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setResults({
                    user: {
                        username: data.user.username,
                        name: data.user.name,
                        picture: data.user.picture
                    },
                    socialMedia: {
                        facebook: data.socialMedia.facebook,
                        instagram: data.socialMedia.instagram,
                        linkedin: data.socialMedia.linkedin,
                        tiktok: data.socialMedia.tiktok,
                        youtube: data.socialMedia.youtube,
                        other: data.socialMedia.other
                    },
                    shares: data.shares,
                    control: {
                        isPublicEnd: data.control.isEnd,
                        publicIndex: data.control.index,
                    }
                });
                setShareCount(data.shares.length);
                setLoading(false);

            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <section className='myProfile-content'>

            {loading ? (
                <ProfileLoading />
            ) : (
                <>
                    <ProfileComponent
                        results={results}
                        shareCount={shareCount}
                    />
                </>
            )}

        </section>
    );
}

export default MyProfile;
