import React from 'react';
import '../style/components/share-loading.css';

function ShareLoading() {
    const placeholders = new Array(4).fill(0);

    return (
        <section className='share-loading-wrapper'>
            <div className='loading-image'></div>
            <div className='loading-content'>
                <div className='loading-details'>
                    <div className='loading-detail-item'>
                        <div className='loading-icon'></div>
                        <div className='loading-text'></div>
                    </div>
                    <div className='loading-detail-item'>
                        <div className='loading-icon'></div>
                        <div className='loading-text'></div>
                    </div>
                    <div className='loading-detail-item'>
                        <div className='loading-icon'></div>
                        <div className='loading-text'></div>
                    </div>
                    <div className='loading-detail-item'>
                        <div className='loading-icon'></div>
                        <div className='loading-text'></div>
                    </div>
                </div>
                <div className='loading-category-sections'>
                    {placeholders.map((_, index) => (
                        <div key={index} className='loading-category-section'>
                            <div className='loading-category-title'></div>
                            <div className='loading-category-buttons'>
                                <div className='loading-button'></div>
                                <div className='loading-button'></div>
                                <div className='loading-button'></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ShareLoading;
