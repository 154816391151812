import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import MainContext from '../context/MainContext'
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import '../style/components/reportProblem.css';

function ReportProblem({ username, share, setReportProblemOpen }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [selectedOption, setSelectedOption] = useState('');
  const [description, setDescription] = useState('');
  const [relatedToUserOrShare, setRelatedToUserOrShare] = useState('');

  const isDisabled = selectedOption && description;
  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === 'problem_share') {
      setRelatedToUserOrShare(share);
    } else if (value === 'problem_user') {
      setRelatedToUserOrShare(username);
    } else {
      setRelatedToUserOrShare('');
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleRelatedChange = (event) => {
    setRelatedToUserOrShare(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log('Selected Option:', selectedOption);
    console.log('Description:', description);
    console.log('Related to:', relatedToUserOrShare);

    const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/username`;

    const formData = new URLSearchParams({
      selectedOption: selectedOption,
      username: username,
      share: share,
      description: description
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setSubmited(true);
          return response.json();
        }
      })
      .then(data => {

      })
      .catch(error => {
        console.error('Error creating account:', error);

      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const closeReportProblem = () => {
    setReportProblemOpen(false);
  };

  return (
    <section className='reportProblem-wrapper'>
      <div className='reportProblem-background-wrapper'>
        <div className='reportProblem-title'>
          <div className='reportProblem-title-leftSide'>
            <h3>{translations.reportProblem_title}</h3>
          </div>
          <div className='reportProblem-title-rightSide'>
            <img src='/common/icon-close.png' alt='Close' onClick={closeReportProblem} />
          </div>
        </div>

        <form className="reportProblem-form" onSubmit={handleSubmit}>
          <div className="reportProblem-options">
            <label htmlFor="problem-select">{translations.reportProblem_selectProblemType}</label>
            <select
              id="problem-select"
              value={selectedOption}
              onChange={handleOptionChange}
              className='reportProblem-options-select'
              required
            >
              <option value="" disabled>{translations.reportProblem_selectAnOption}</option>
              <option value="problem_share">{translations.reportProblem_problemWithShare}</option>
              <option value="problem_user">{translations.reportProblem_problemWithUser}</option>
              <option value="bug">{translations.reportProblem_reportBug}</option>
              <option value="suggestion">{translations.reportProblem_makeSuggestion}</option>
              <option value="other">{translations.reportProblem_other}</option>
            </select>
          </div>

          {/* Conditional question for Share/User related problems */}
          {selectedOption === 'problem_share' && (
            <div className="reportProblem-related">
              <p>{translations.reportProblem_isThisProblemRelatedToShare}</p>
              <label>
                <input
                  type="radio"
                  value={share}
                  checked={relatedToUserOrShare === share}
                  className='reportProblem-related-input'
                  onChange={handleRelatedChange}
                />
                {translations.reportProblem_yes}
              </label>
              <label>
                <input
                  type="radio"
                  value="No"
                  checked={relatedToUserOrShare === 'No'}
                  className='reportProblem-related-input'
                  onChange={handleRelatedChange}
                  required
                />
                {translations.reportProblem_no}
              </label>
            </div>
          )}

          {selectedOption === 'problem_user' && (
            <div className="reportProblem-related">
              <p>{translations.reportProblem_isThisProblemRelatedToUser}</p>
              <label>
                <input
                  type="radio"
                  value={username}
                  checked={relatedToUserOrShare === username}
                  className='reportProblem-related-input'
                  onChange={handleRelatedChange}
                  required
                />
                {translations.reportProblem_yes}
              </label>
              <label>
                <input
                  type="radio"
                  value="No"
                  checked={relatedToUserOrShare === 'No'}
                  className='reportProblem-related-input'
                  onChange={handleRelatedChange}
                  required
                />
                {translations.reportProblem_no}
              </label>
            </div>
          )}

          <div className="reportProblem-description">
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              placeholder={translations.reportProblem_description}
              rows="5"
              required
            />
          </div>

          {submited ? (
            <div className="reportProblem-submited">
              <span>{translations.reportProblem_submited}</span>
            </div>
          ) : (
            <div className="reportProblem-submit">
              {
                loading ? (
                  <button disabled>
                    <img src='/loaders/icon-loading.png' alt='Loading' />
                  </button>
                ) : (
                  <button type="submit" disabled={!isDisabled}>{translations.reportProblem_submitReport}</button>
                )}
            </div>
          )}


        </form>
      </div >
    </section >
  );
}

export default ReportProblem;
