import React, { useState, useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import MainContext from '../../../context/MainContext'
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/LandingPage/Section8_Contacts.css';

function Section8_Contacts() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [message, setMessage] = useState('');

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Simple email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailPattern.test(emailValue));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if(name === '' || message === '') {
            setLoading(false);
            setError(translations.landingPage_section8_allFieldsRequired);
            return;
        }

        const baseURL = `${MainContext.apiURL}/api/v1/public/contacts/send/message`;

        const formData = new URLSearchParams({
            name: name,
            email: email,
            message: message
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    setError(translations.landingPage_section8_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                setError('');
                setSuccess(translations.landingPage_section8_messageSent);
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoading(false);
                setError(translations.landingPage_section8_somethingWentWrong);
            });
    };

    return (
        <section className='section8_contacts-content'>
            <div className='section8_contacts-content-wrapper'>
                <div className='section8_contacts-leftSide'>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24884.074077024852!2d-9.118272425752336!3d38.7749582844585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193189052ee36b%3A0x3db21bf12cb79e6e!2zUGFycXVlIGRhcyBOYcOnw7VlcywgTGlzYm9h!5e0!3m2!1spt-PT!2spt!4v1725802886334!5m2!1spt-PT!2spt"
                        loading="lazy" title='Maps' />

                </div>
                <div className='section8_contacts-rightSide'>
                    <div className='section8_contacts-rightSide-wrapper'>
                        <h4>{translations.landingPage_section8_leaveUsMessage}</h4>
                        <div className='section8_contacts-rightSide-form'>
                            <form>
                                <input type="text" placeholder={translations.landingPage_section8_namePlaceholder} className='section8_contacts-input' value={name} onChange={(e) => setName(e.target.value)} required/>
                                <input type="email" placeholder={translations.landingPage_section8_emailPlaceholder} className='section8_contacts-input' value={email} onChange={handleEmailChange} required/>
                                <textarea placeholder={translations.landingPage_section8_yourMessage} className='section8_contacts-textarea' value={message} onChange={(e) => setMessage(e.target.value)} required />
                                {error !== '' && (
                                    <span className='section8_contacts-error'>{error}</span>
                                )}
                                {success !== '' ? (
                                    <span className='section8_contacts-success'>{success}</span>
                                ) : (
                                    <>
                                        {loading ? (
                                            <button disabled className='section8_contacts-loader'>
                                                <img src='/loaders/icon-loading.png' alt='Loading' />
                                            </button>
                                        ) : (
                                            <button type="submit" className='section8_contacts-submitButton' onClick={sendEmail} disabled={!isEmailValid}>{translations.landingPage_section8_sendMessage}</button>
                                        )}
                                    </>
                                )}
                            </form>
                        </div>

                        <div className='section8_contacts-separator'></div>

                        <div className='section8_contacts-rightSide-socialMedia'>
                            <p>{translations.landingPage_section8_socialMedia}</p>
                            <div className='section8_contacts-socialIcons'>
                                <a href="https://www.facebook.com/profile.php?id=61557251661638" target="_blank" rel="noreferrer">
                                    <img src="/socials/icon-facebook.png" alt="Facebook" />
                                </a>
                                <a href="https://www.instagram.com/matchthelook_com" target="_blank" rel="noreferrer">
                                    <img src="/socials/icon-instagram.png" alt="Instagram" />
                                </a>
                                <a href="https://www.linkedin.com/company/matchthelook/" target="_blank" rel="noreferrer">
                                    <img src="/socials/icon-linkedin.png" alt="Linkedin" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Section8_Contacts;
