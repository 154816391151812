import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import Step1 from './ReferralRegister/Step1';
import Step2 from './ReferralRegister/Step2';
import Step3 from './ReferralRegister/Step3';
import Step4 from './ReferralRegister/Step4';
import '../../style/auth/ReferralRegister.css';

function Register() {
    const { username, referralcode } = useParams();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [processedUsername, setProcessedUsername] = useState(username.toLocaleLowerCase());
    const [loadingPage, setLoadingPage] = useState(true);
    const [userPicture, setUserPicture] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLoadingPage(true);

        if (username.startsWith('@')) {
            setProcessedUsername(username.substring(1));
            validateReferralCode(username.substring(1), referralcode);
        } else {
            window.location.href = "/" + language + "/@" + username + "/referral/" + referralcode;
        }
    }, [username]);
    /* eslint-disable react-hooks/exhaustive-deps */

    const validateReferralCode = (username, referralcode) => {
        const data = new URLSearchParams({
            username: username.toLowerCase(),
            referralcode: referralcode
        });

        fetch(`${MainContext.apiURL}/api/v1/public/users/verify/referral?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    setLoadingPage(false);
                    return response.json();
                } else {
                    window.location.href = "/" + language + "/auth/register";
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {
                setUserPicture(data.picture);
            })
            .catch(error => {
            });
    };

    const [step, setStep] = useState(1);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [errorOTPCode, setErrorOTPCode] = useState(false);

    const [loadingButton, setLoadingButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isStep1Complete = firstName && lastName && gender && birthday && country;
    const isStep2Complete = email && password && repeatPassword && !emailError && password === repeatPassword;

    const isStep3Complete = OTPCode && !errorOTPCode;

    // Function to validate password
    const isPasswordValid = (pwd) => {
        const minLength = 6;
        const passwordRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{${minLength},}$`);
        return passwordRegex.test(pwd);
    };

    useEffect(() => {
        if (password === repeatPassword) {
            setErrorPassword(false);
        } else if ((password !== repeatPassword || !isPasswordValid(password)) && (password !== '' || repeatPassword !== '')) {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }
    }, [password, repeatPassword]);

    function validateBeforeRegister() {
        if (password !== repeatPassword) {
            setErrorPassword(true);
        } else {
            setEmailError(false);
            setErrorPassword(false);
            verifyEmailAddress();
        }
    }

    const verifyEmailAddress = () => {
        setLoadingButton(true);
        const data = new URLSearchParams({
            email: email.toLowerCase(),
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/verify-email?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    setEmailError(false);
                    setErrorMessage('');
                    registerUser();
                    return response.json();
                } else {
                    setLoadingButton(false);
                    setEmailError(true);
                    setErrorMessage(translations.referralCode_userAlreadyRegister);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {

            })
            .catch(error => {
            });
    };

    const registerUser = () => {
        setLoadingButton(true);
        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/referral/create-account`;

        const formData = new URLSearchParams({
            email: email,
            password: password,
            name: firstName,
            surname: lastName,
            gender: gender,
            country: country,
            birthday: birthday,
            language: language,
            referralcode: referralcode
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoadingButton(false);
                    setErrorMessage(translations.referralCode_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoadingButton(false);
                    setStep(3);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error creating account:', error);
                setLoadingButton(false);
                setErrorMessage(translations.referralCode_somethingWentWrong);
            });
    };

    const activateAccount = (e) => {
        e.preventDefault();
        setLoadingButton(true);

        const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/activate-account`;

        const formData = new URLSearchParams({
            email: email,
            securityToken: OTPCode
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoadingButton(false);
                    setErrorMessage(translations.referralCode_somethingWentWrong);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    setLoadingButton(false);
                    setErrorMessage('');
                    setStep(4);
                    return response.json();
                }
            })
            .then(data => {
            })
            .catch(error => {
                console.error('Error activating account:', error);
                setLoadingButton(false);
                setErrorMessage(translations.referralCode_somethingWentWrong);
            });
    };

    return (
        <section className='referral-register-content'>

            {loadingPage ? (
                <div className='referral-loadingPage'>
                    <img src='/loaders/icon-loading.png' alt='Loading' />
                </div>
            ) : (
                <>
                    <div className='referral-register-leftSide-container'>
                        <div className='referral-register-leftSide-logo'>
                            <a href={`/${language}`}><h1>Match the Look</h1></a>
                        </div>
                        <div className='referral-register-leftSide-action'>
                            <span>{translations.referralCode_alreadyHaveAccount}</span>
                            <a href={`/${language}/auth/login`}>{translations.referralCode_logIn}</a>
                        </div>
                        {step === 1 && (
                            <>
                                <Step1
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    gender={gender}
                                    setGender={setGender}
                                    birthday={birthday}
                                    setBirthday={setBirthday}
                                    country={country}
                                    setCountry={setCountry}
                                    language={language}
                                    MainContext={MainContext}
                                    referralcode={referralcode}
                                    translations={translations}
                                />

                                <div className='referral-register-leftSide-container-form-button'>
                                    <button onClick={() => setStep(2)} disabled={!isStep1Complete}>{translations.referralCode_nextStep}</button>
                                </div>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <Step2
                                    email={email}
                                    setEmail={setEmail}
                                    emailError={emailError}
                                    setEmailError={setEmailError}
                                    password={password}
                                    setPassword={setPassword}
                                    repeatPassword={repeatPassword}
                                    setRepeatPassword={setRepeatPassword}
                                    errorPassword={errorPassword}
                                    translations={translations}
                                />

                                {errorMessage !== '' && (
                                    <div className='referral-register-error-message'>
                                        <span>{errorMessage}</span>
                                    </div>
                                )}

                                <div className='referral-register-leftSide-container-form-button'>
                                    {loadingButton ? (
                                        <button disabled>
                                            <img src='/loaders/icon-loading.png' alt='Loading' />
                                        </button>
                                    ) : (
                                        <button onClick={validateBeforeRegister} disabled={!isStep2Complete}>{translations.referralCode_createAccount}</button>
                                    )}
                                </div>
                            </>
                        )}

                        {step === 3 && (
                            <>
                                <Step3
                                    email={email}
                                    OTPCode={OTPCode}
                                    setOTPCode={setOTPCode}
                                    error={errorOTPCode}
                                    setError={setErrorOTPCode}
                                    translations={translations}
                                />

                                <div className='referral-register-leftSide-container-form-button'>
                                    {loadingButton ? (
                                        <button disabled>
                                            <img src='/loaders/icon-loading.png' alt='Loading' />
                                        </button>
                                    ) : (
                                        <button onClick={activateAccount} disabled={!isStep3Complete}>{translations.referralCode_confirm}</button>
                                    )}
                                </div>
                            </>
                        )}

                        {step === 4 && (
                            <>
                                <Step4
                                    translations={translations}
                                />
                                <div className='referral-register-leftSide-container-form-button'>
                                    <a href={`/${language}/auth/login`}><button>{translations.referralCode_goToLogin}</button></a>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='referral-register-rightSide-container'>
                        <div className='referral-register-rightSide-box-container'>
                            <div className='referral-register-rightSide-box-profilePicture'>
                                {userPicture !== null ? (
                                    <img src={`${MainContext.profilePictureURL}${userPicture}`} alt='User Profile' />
                                ) : (
                                    <img src='/common/icon-default-picture.png' alt='User Profile Default' />
                                )}
                            </div>
                            <div className='referral-register-rightSide-box-quote'>
                                <p><b>@{processedUsername}</b> {translations.referralCode_hasInvitedYouToJoin}</p>
                                <p>{translations.referralCode_welcomeMessage}</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}

export default Register;
