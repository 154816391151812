import React, { useEffect, useState, createContext, useContext } from 'react';
import MainContext from './MainContext';

const CSRFTokenContext = createContext();

export const CSRFTokenProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState('');
    const [loadingCSRFToken, setLoadingCSRFToken] = useState(true);

    useEffect(() => {
        async function fetchToken() {
            try {
                const response = await fetch(`${MainContext.apiURL}/api/v1/csrf-token`);
                const data = await response.json();
                setCsrfToken(data.csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            } finally {
                setLoadingCSRFToken(false);
            }
        }

        fetchToken();
    }, []);

    return (
        <CSRFTokenContext.Provider value={{ csrfToken, loadingCSRFToken }}>
            {children}
        </CSRFTokenContext.Provider>
    );
};

export const useCSRFToken = () => useContext(CSRFTokenContext);
